import type { ColumnDef } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { Skeleton } from '~/components/ui/skeleton.tsx';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '~/components/ui/table';
import { cn } from '~/lib/utils.ts';

interface DataTableProps<TData> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<TData, any>[];
  data: TData[];
}

export function ArtistDataTable<TData>({
  columns,
  data,
}: DataTableProps<TData>) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Table className={'text-left'}>
      <TableHeader>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <TableHead
                  key={header.id}
                  className={'text-body-xs font-normal'}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </TableHead>
              );
            })}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {table.getRowModel().rows?.length
          ? table.getRowModel().rows.map((row, index) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && 'selected'}
                className={cn(
                  'p-sm rounded-sm',
                  index % 2 === 0 && 'bg-level-05',
                )}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} className={''}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          : Array(10)
              .fill(0)
              .map((_, i) => (
                <TableRow
                  key={i}
                  className={cn(
                    'p-sm rounded-sm',
                    i % 2 === 0 && 'bg-level-05',
                  )}
                >
                  {table.getHeaderGroups()[0].headers.map((cell) => (
                    <TableCell key={cell.id} className={''}>
                      <div className="space-y-sm">
                        <Skeleton className="bg-level-02 w-[12em] h-md rounded-sm" />
                        <Skeleton className="bg-level-03 w-[10em] h-md rounded-sm" />
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
      </TableBody>
    </Table>
  );
}

ArtistDataTable.displayName = 'ArtistDataTable';
