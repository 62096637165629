import i18next from 'i18next';
import { useMemo } from 'react';

import { useUser } from '~/modules/auth/hooks/useUser';
import { bffClient } from '~/modules/auth/lib/bff-client';

import { remotes } from '../registry';
import type { IRemoteContext } from '../types';

type RemoteContextMap = {
  [path: string]: {
    context: IRemoteContext;
    isolateStyles: boolean;
  };
};

const useRemoteContexts = () => {
  const { name, hasPermission, email } = useUser();

  const remoteContexts = useMemo(
    () =>
      remotes.reduce<RemoteContextMap>((map, remote) => {
        map[remote.name] = {
          context: {
            prefix: remote.prefix,
            name: remote.name,
            refreshAuthToken: bffClient.refreshAuthToken,
            user: {
              name,
              hasPermission,
              email,
            },
            locale: i18next.language,
          },
          isolateStyles: remote.isolateStyles ?? false,
        };
        return map;
      }, {}),
    [hasPermission, name, email],
  );

  return remoteContexts;
};

export { useRemoteContexts };
export type { RemoteContextMap };
