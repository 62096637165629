import type {
  BaseEntity,
  Label,
  LabelDetailed,
  User,
  UserDetails,
  UserLabel,
} from '~settings/types';
import { LabelType } from '~settings/types';

export const transformUsers = (data: { [key: string]: unknown }[] = []) =>
  data.map(
    (user): User =>
      ({
        id: user.id,
        fullName: user.fullName,
        firstName: user.firstName,
        lastName: user.lastName,
        isActive: user.isActive,
        email: user.email,
        isExternal: user.external,
        labelGroups: (user.labelGroups as Array<{ name: string }>).map(
          (g) => g.name,
        ),
        labelsNames: (user.labels as Array<BaseEntity>).map(
          (label) => label.name,
        ),
        lastLogin: user.lastLogin,
        roles: user.roles ?? [],
      }) as User,
  );

export const transformLabels = (data: { [key: string]: unknown }[] = []) => {
  return data.map(
    (label): Partial<Label> =>
      ({
        id: label.id,
        name: label.name,
        isActive: label.active,
        type: label.isParentLabel ? LabelType.PARENT_LABEL : LabelType.SUBLABEL,
        usersCount: label.usersCount,
        sublabelsCount: label.sublabelsCount,
        groupsNames:
          (label.labelGroups as Array<BaseEntity>)?.map((g) => g.name) ?? [],
      }) as Label,
  );
};

export const transformLabelDetails = (data: {
  [key: string]: unknown;
}): LabelDetailed => {
  const socials = [
    'facebookReference',
    'twitterReference',
    'website',
    'youtubeReference',
    'instagramReference',
  ].reduce((acc: Array<[string, string]>, key) => {
    if (data[key]) {
      let value = data[key] as string;
      if (!/^(https?:)/.test(value)) {
        value = `https://${value}`;
      }
      acc.push([key, value]);
    }
    return acc;
  }, []);

  const users = transformUsers(data.users as { [key: string]: unknown }[]);

  return {
    id: data.id,
    name: data.name,
    isActive: data.active,
    type: data.parentId ? LabelType.SUBLABEL : LabelType.PARENT_LABEL,
    parentId: data.parentId,
    parentName: data.parentName,
    sublabels: data.sublabels,
    sublabelsCount: (data.sublabels as Array<BaseEntity>)?.length,
    groupsNames: (data.labelGroups as Array<BaseEntity>)?.map((g) => g.name),
    dealRegionScope: data.dealRegionScope,
    dealOwner: data.dealOwner,
    weaLabelCode: data.weaLabelCode,
    distributionGroups: data.distributionGroups,
    socials,
    users,
    adminUsers:
      users.filter((user) =>
        user.roles.some((role) => role.includes('label admin')),
      ) ?? [],
  } as LabelDetailed;
};

export const transformUserDetails = (user: {
  [key: string]: unknown;
}): UserDetails => {
  const fullName: string =
    (user.fullName as string) || `${user.firstName} ${user.lastName}`;

  return {
    id: user.id,
    fullName,
    firstName: user.firstName,
    lastName: user.lastName,
    isActive: user.isActive,
    email: user.email,
    phone: user.phone,
    isExternal: user.external,
    lastLogin: user.lastLogin,
    labels: (user.labels as UserLabel[]).map((label) => {
      return {
        ...label,
        type: label.isParentLabel ? LabelType.PARENT_LABEL : LabelType.SUBLABEL,
        roles: label.roles ?? [],
      };
    }),
  } as UserDetails;
};
