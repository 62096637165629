import { Route, Routes } from 'react-router-dom';

import { UserDetails } from '~settings/UserDetails';

import { TabsPagesLayout } from './components';
import { LabelDetails } from './LabelDetails';
import { Labels } from './Labels';
import { Users } from './Users';
/**
 * Top-level router combining module-level routers
 */
const Router = () => {
  return (
    <Routes>
      <Route element={<TabsPagesLayout />}>
        <Route path="labels" element={<Labels />} />
        <Route path="*" element={<Users />} />
      </Route>
      <Route path="labels/:uuid" element={<LabelDetails />} />
      <Route path="users/:uuid" element={<UserDetails />} />
    </Routes>
  );
};

Router.displayName = 'SettingsRouter';

export { Router };
