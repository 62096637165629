import { createContext } from 'react';

import type { UserWithActions } from '..';

const UserContext = createContext<UserWithActions>({
  email: '',
  name: '',
  permissions: {},
  hasPermission: () => false,
});

UserContext.displayName = 'UserContext';

export { UserContext };
