import acidIcon from './acidIcon.png?url';
import adaHubIcon from './adaHubIcon.png?url';
import airtableIcon from './airtableIcon.png?url';
import arrowIcon from './arrowIcon.png?url';
import artistPackIcon from './artistPackIcon.png?url';
import galleryIcon from './galleryIcon.png?url';
import luminateIcon from './luminateIcon.png?url';
import mondayIcon from './mondayIcon.png?url';
import opusIcon from './opusIcon.png?url';
import serviceNowIcon from './serviceNowIcon.png?url';
import sodatoneIcon from './sodatoneIcon.png?url';
import songRadarIcon from './songRadarIcon.png?url';
import sunIcon from './sunIcon.png?url';
import tableauIcon from './tableauIcon.png?url';
import warnerBlackIcon from './warnerBlackIcon.png?url';
import warnerBlueIcon from './warnerBlueIcon.png?url';
import warnerChappellGoldIcon from './warnerChappellGoldIcon.png?url';
import warnerChappellIcon from './warnerChappellIcon.png?url';
import wavoIcon from './wavoIcon.png?url';

export const ICON_BY_TYPE: Record<string, string> = {
  ACID: acidIcon,
  AIRTABLE: airtableIcon,
  WARNER_BLACK: warnerBlackIcon,
  WARNER_BLUE: warnerBlueIcon,
  ARTIST_PACK: artistPackIcon,
  WAVO: wavoIcon,
  LUMINATE: luminateIcon,
  SODATONE: sodatoneIcon,
  TABLEAU: tableauIcon,
  SERVICE_NOW: serviceNowIcon,
  OPUS: opusIcon,
  ADA_HUB: adaHubIcon,
  SUN: sunIcon,
  MONDAY: mondayIcon,
  CHAPPELL: warnerChappellIcon,
  CHAPPELL_GOLD: warnerChappellGoldIcon,
  ARROW: arrowIcon,
  GALLERY: galleryIcon,
  SONG_RADAR: songRadarIcon,
};
