import { Spinner } from '~/components/Spinner';

const LoadingScreen = () => {
  return (
    <div
      className="flex justify-center items-center h-screen flex-1"
      role="status"
    >
      <Spinner />
    </div>
  );
};

LoadingScreen.displayName = 'LoadingScreen';

export { LoadingScreen };
