import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { type FC, useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { ErrorPage } from '~/components/ErrorPage';
import { BrandContext } from '~/modules/branding';
import { LoadingScreen } from '~/modules/navigation/components/LoadingScreen';
import { BRANDS } from '~/shared/lib/brands';

import { isKnownPath } from '../lib/utils';

/**
 * Denies access to Outlet and redirect in case of unauthenticated users.
 */
export const SecureRoute: FC = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const brand = useContext(BrandContext);

  useEffect(() => {
    // When Okta finished loading, but user isn't authenticated
    if (authState && !authState.isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin,
      );

      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
  }, [oktaAuth, authState]);

  if (!authState || !authState?.isAuthenticated) {
    return <LoadingScreen />;
  }

  if (brand.name === BRANDS.ADA && !isKnownPath(window.location.pathname)) {
    return <ErrorPage type="notFoundPage" />;
  }

  return <Outlet />;
};

SecureRoute.displayName = 'SecureRoute';
