import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { Table } from '~settings/components/Table';
import { useBffClient } from '~settings/hooks/useBffClient';
import { BffAction } from '~settings/modules/types';

import { Toolbar } from '../components/Toolbar';
import { columns } from './columns.config';

export const Labels = () => {
  const fetchLabels = useBffClient(BffAction.FETCH_LABELS);
  const { t } = useTranslation('settings');
  const { data, isLoading } = useQuery({
    queryKey: ['labels'],
    queryFn: fetchLabels,
  });

  if (isLoading) {
    return <section className="py-md">Loading...</section>;
  }
  const labels = data?.data ?? [];

  return (
    <>
      <Toolbar placeholder={t('searchLabels')} />
      <Table data={labels} columns={columns} type="labels" />
    </>
  );
};

Labels.displayName = 'Labels';
