import { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';

import {
  NAVIGATION_PERMISSIONS,
  NavigationPermissionKeys,
} from '~/lib/navigationPermissions.ts';
import { Logout } from '~/modules/auth/components/Logout.tsx';
import { RefreshAuth } from '~/modules/auth/components/RefreshAuth.tsx';
import { SecureRoute } from '~/modules/auth/components/SecureRoute';
import { useUser } from '~/modules/auth/hooks/useUser.ts';
import { AuthRouter } from '~/modules/auth/router';
import { BrandContext } from '~/modules/branding';
import { LinkFarmRouter } from '~/modules/link-farm/router';
import { RemoteRouter } from '~/modules/remotes/router.tsx';

import { ErrorPage } from './components/ErrorPage';
import { App as RosterApp } from './modules/artist-roster/App';
import { PrimaryLayout } from './modules/navigation/components/PrimaryLayout';
import { App as SettingsApp } from './modules/settings/App';

/**
 * Top-level router combining module-level routers
 */
const Router = () => {
  const brand = useContext(BrandContext);
  const { hasPermission, ...user } = useUser();
  const canAccessSettings = NAVIGATION_PERMISSIONS[
    NavigationPermissionKeys.SETTINGS
  ](hasPermission, { user });

  const rootRoute =
    brand.name === 'WMG' ? <LinkFarmRouter /> : <RemoteRouter />;

  return (
    <Routes>
      <Route path="login/*" element={<AuthRouter />} />
      <Route element={<SecureRoute />}>
        <Route path="logout" element={<Logout />} />
        <Route path="auth/refresh" element={<RefreshAuth />} />
        <Route element={<PrimaryLayout />}>
          <Route path="*" element={rootRoute} />
          <Route
            path="settings/*"
            element={
              canAccessSettings ? (
                <SettingsApp />
              ) : (
                <ErrorPage type="noAccessPage" />
              )
            }
          />
          <Route path="roster/*" element={<RosterApp />} />
        </Route>
      </Route>
    </Routes>
  );
};

Router.displayName = 'Router';

export { Router };
