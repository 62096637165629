import React from 'react';

import { Icon } from '~/components';
import { mergeClasses } from '~/lib/mergeClasses';

interface BreadcrumbsRootProps extends React.ComponentPropsWithoutRef<'nav'> {
  children: NonNullable<React.ReactNode>;

  separator?: React.ReactNode;
}

export function BreadcrumbsRoot({
  children,
  className,
  separator = (
    <Icon type="chevron-right" size="xs" role="presentation" aria-hidden />
  ),
  ...delegatedProps
}: BreadcrumbsRootProps) {
  const childrenWithSeparators = React.useMemo(() => {
    const validChildren = React.Children.toArray(children).filter(
      Boolean,
    ) as React.ReactElement[];

    return validChildren.map((child, index) => {
      return index ? (
        <React.Fragment key={child.key}>
          {separator}
          {child}
        </React.Fragment>
      ) : (
        child
      );
    });
  }, [children, separator]);

  if (!childrenWithSeparators?.length) return null;

  return (
    <nav
      className={mergeClasses(
        'flex',
        'items-center',
        'gap-md',
        'text-subtle',
        'min-h-[1.5rem]',
        className,
      )}
      {...delegatedProps}
    >
      {childrenWithSeparators}
    </nav>
  );
}
BreadcrumbsRoot.displayName = 'BreadcrumbsRoot';
