import { useCallback, useContext } from 'react';

import { UserContext } from '~/modules/auth/contexts/UserContext.tsx';

const useUser = () => {
  const { email, name, permissions } = useContext(UserContext);

  const hasPermission = useCallback(
    (permission: string, entityID?: string) => {
      // for unscoped permissions, we can use any entity, so we just take the first one
      const id = entityID || Object.keys(permissions)[0];
      return permissions[id]?.includes(permission) ?? false;
    },
    [permissions],
  );

  return {
    email,
    name,
    permissions,
    hasPermission,
  };
};

export { useUser };
