import type { WidgetOptions } from '@okta/okta-signin-widget';

import { env } from '~/lib/env.ts';

const clientId = env('VITE_ADA_OKTA_CLIENT_ID', true);
const issuer = env('VITE_ADA_OKTA_ISSUER', true);
const baseUrl = `https://${env('VITE_ADA_OKTA_DOMAIN', true)}`;
const redirectUri = `${window.location.origin}${env('ADA_BASE_URL', false) ?? ''}login/callback`;
const helpUrl = env('VITE_ADA_OKTA_HELP_URL', false);

export const AdaOktaConfig = {
  baseUrl,
  clientId,
  redirectUri,
  authParams: {
    pkce: true,
    scopes: ['openid', 'profile', 'email', 'groups', 'offline_access'],
    issuer,
  },
  useInteractionCodeFlow: false,
  useClassicEngine: true,
  features: {
    autoPush: true,
    registration: false,
    rememberMe: true,
    showPasswordRequirementsAsHtmlList: true,
    idpDiscovery: true,
    selfServiceUnlock: true,
  },
  idpDiscovery: {
    requestContext: redirectUri,
  },
  colors: {
    brand: '#E70070',
  },
  helpLinks: {
    help: helpUrl,
  },
  i18n: {
    en: {
      'password.reset': 'Reset Password',
      'password.forgot.email.or.username.tooltip': 'Email',
      'password.forgot.email.or.username.placeholder':
        'To reset your password, enter your email and we’ll send you a reset link.',
      'password.forgot.sendEmail': 'Send reset link',
      goback: 'Cancel',
      'password.reset.title.generic': 'Reset your password',
      'enroll.totp.title': '{0}',
      'enroll.choices.title': 'Sign In',
      'factor.oktaVerifyPush': 'Verify',
      'enroll.choices.description.generic':
        'We require multifactor authentication to add an additional layer of security when signing into your account',
      // Labels
      'primaryauth.username.placeholder': 'Email',
    },
  },
} satisfies WidgetOptions;
