const translationMap = {
  AR: 'ar',
  ar: 'AR',
  EN: 'en-US',
  'en-US': 'EN',
  ES: 'es',
  es: 'ES',
  FR: 'fr',
  fr: 'FR',
  JP: 'ja-JP',
  'ja-JP': 'JP',
  ko_KR: 'ko-KR',
  'ko-KR': 'ko_KR',
  pt_BR: 'pt-BR',
  'pt-BR': 'pt_BR',
  CMN: 'zh-CN',
  'zh-CN': 'CMN',
};

export function convertCoopLocale(locale: string) {
  if (!(locale in translationMap)) {
    throw new Error(`Invalid locale [${locale}]`);
  }

  return translationMap[locale as keyof typeof translationMap];
}
