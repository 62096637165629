import { env } from '~/lib/env';

import { initAmplitude } from './amplitude';
import { initNewRelicAgent } from './new-relic';

const startMonitoring = () => {
  const beacon = env('VITE_NR_BEACON', false);
  const licenseKey = env('VITE_NR_LICENSE_KEY', false);
  const agentID = env('VITE_NR_AGENT_ID', false);
  const trustKey = env('VITE_NR_TRUST_KEY', false);
  const applicationID = env('VITE_NR_APPLICATION_ID', false);
  const accountID = env('VITE_NR_ACCOUNT_ID', false);

  initNewRelicAgent({
    beacon,
    licenseKey,
    agentID,
    trustKey,
    applicationID,
    accountID,
  });

  const amplitudeApiKey = env('VITE_AMPLITUDE_API_KEY', false);
  initAmplitude(amplitudeApiKey);
};

export { startMonitoring };
