import { useTranslation } from 'react-i18next';

import { HeaderSmall } from '~settings/components/HeaderSmall';
import { Table } from '~settings/components/Table.tsx';
import type { UserLabel } from '~settings/types.ts';
import { columns } from '~settings/UserDetails/columns.config';

interface Props {
  labels: UserLabel[];
}
export const UserLabels = ({ labels }: Props) => {
  const { t } = useTranslation('settings');
  return (
    <>
      <HeaderSmall>{t('labels')}</HeaderSmall>
      <Table
        data={labels}
        columns={columns}
        oddRowClassName="bg-level-03"
        type="labels"
      />
    </>
  );
};

UserLabels.displayName = 'UserLabels';
