import { useTranslation } from 'react-i18next';

import { Icon, Separator } from '~/components';

import { HeaderSmall } from '../components/HeaderSmall';
import type { LabelDetailed } from '../types';

const namesToIconsMap: Record<string, string> = {
  facebookReference: 'facebook',
  instagramReference: 'instagram',
  twitterReference: 'x-twitter',
  youtubeReference: 'youtube',
  website: 'earth-americas',
};

export const Socials = ({ data }: { data: LabelDetailed }) => {
  const { t } = useTranslation('settings');
  if (!data.socials?.length) return null;

  return (
    <div className="text-body-md">
      <Separator className="my-lg" />
      <HeaderSmall> {t('socials')}</HeaderSmall>
      {data.socials.map(
        ([type, url]: [keyof typeof namesToIconsMap, string]) => (
          <a
            key={type}
            href={url}
            className="text-inverse"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon
              type={namesToIconsMap[type]}
              variant={type === 'website' ? 'solid' : 'brands'}
              className="mr-xs w-md h-md"
              aria-hidden="true"
              size="md"
            />
          </a>
        ),
      )}
    </div>
  );
};

Socials.displayName = 'Socials';
