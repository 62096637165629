import type { WidgetOptions } from '@okta/okta-signin-widget';
import type { JSX } from 'react';
import { createContext, Fragment } from 'react';

type Brand = {
  name: 'WMG' | 'ADA' | null;
  title: string;
  logo?: JSX.Element;
  meta: JSX.Element;
  oktaConfig: WidgetOptions;
};

const defaultBrandContext: Brand = {
  name: null,
  title: '',
  oktaConfig: {},
  meta: <Fragment />,
};

const BrandContext = createContext<Brand>(defaultBrandContext);

BrandContext.displayName = 'BrandContext';

export { BrandContext, defaultBrandContext };
export type { Brand };
