import { type FC, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { ErrorPage } from '~/components/ErrorPage';
import { ErrorBoundary } from '~/modules/errors/components/ErrorBoundary.tsx';
import { WmgOneLayout } from '~/modules/link-farm/components/WmgOneLayout';
import { Home } from '~/modules/link-farm/Home.tsx';
import { LinkPage } from '~/modules/link-farm/LinkPage.tsx';

import linkFarmLinks from './linkFarmLinks.json';

export const LinkFarmRouter: FC = () => {
  const linkCategories = Object.keys(linkFarmLinks).map((category) =>
    category.toLowerCase(),
  );
  return (
    <ErrorBoundary>
      <Suspense>
        <Routes>
          <Route element={<WmgOneLayout />}>
            <Route index path="/" element={<Home />} />
            {linkCategories.map((category) => (
              <Route
                key={category}
                path={category}
                element={<LinkPage category={category} />}
              />
            ))}
          </Route>
          <Route path="*" element={<ErrorPage type="notFoundPage" />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
};

LinkFarmRouter.displayName = 'LinkFarmRouter';
