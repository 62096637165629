import { useParams } from 'react-router-dom';

import { Tabs } from '~settings/components/Tabs';
import { useLabelDetails } from '~settings/hooks/useLabelDetails';
import { LabelDetailsHeader } from '~settings/LabelDetails/LabelDetailsHeader';

import { ContentBox } from '../components/ContentBox';
import { LabelType } from '../types';
import { DistributionTerritories } from './DistributionTerritories';
import { LabelAdmins } from './LabelAdmins';
import { LabelGroups } from './LabelGroups';
import { LabelMetadata } from './LabelMetadata';
import { Socials } from './Socials';
import { Sublabels } from './Sublabels';
import { Users } from './Users';

export const LabelDetails = () => {
  const { uuid } = useParams();

  const { data, isLoading, isError } = useLabelDetails(uuid);

  if (isLoading) {
    return <section className="py-md">Loading...</section>;
  }

  if (isError || !data) {
    // eslint-disable-next-line i18next/no-literal-string
    return <>Failed to fetch label details</>;
  }

  const { sublabels, users } = data;
  const isSublabel = data.type === LabelType.SUBLABEL;

  const tabs = [
    {
      title: `Sublabels (${sublabels.length})`,
      component: <Sublabels sublabels={sublabels} />,
    },
    {
      title: `Users (${users.length})`,
      component: <Users users={users} isSublabel={isSublabel} />,
    },
  ];

  return (
    <>
      <LabelDetailsHeader data={data} />
      <article className="py-md flex gap-md">
        <section className="text-body-sm flex-none basis-[20%] min-w-[19rem] inline-flex flex-col gap-md">
          <ContentBox>
            <LabelMetadata data={data} />

            <DistributionTerritories data={data} />

            <LabelGroups data={data} />

            <Socials data={data} />
          </ContentBox>
          <LabelAdmins data={data} />
        </section>
        <ContentBox className="flex-1 rounded-sm py-lg">
          {data.parentId ? (
            <Users users={users} isSublabel={isSublabel} />
          ) : (
            <Tabs tabs={tabs} />
          )}
        </ContentBox>
      </article>
    </>
  );
};

LabelDetails.displayName = 'LabelDetails';
