import { useEffect, useMemo } from 'react';

import { updatePi } from './PulseInsights.utils.ts';

interface PulseInsightsProps {
  email: string;
  lang: string;
  token: string;
}

export const PulseInsights = ({
  email,
  lang,
  token,
}: PulseInsightsProps): null => {
  const userId = useMemo(() => btoa(email), [email]);
  const userType = useMemo((): 'internal' | 'external' => {
    // A bit of a hack to identify "internal" vs "external" user types. This
    // will work for now, until we're able to get the correct info from a more
    // reliable source. Some users who are marked as "internal" in OKTA may
    // show up as "external" and vice versa, but we can't rely on OKTA info
    // directly due to swapping between multiple tenants.
    if (
      /@(wmg\.com|warnermusic\.com|ada-music\.com|ada-music\.co\.uk)$/i.test(
        email,
      )
    ) {
      return 'internal';
    }

    return 'external';
  }, [email]);

  useEffect(() => {
    if (!token) {
      return;
    }

    updatePi(
      {
        localizationCode: lang,
        userId,
        userType,
      },
      { token },
    );
  }, [lang, token, userId, userType]);

  return null;
};

PulseInsights.displayName = 'PulseInsights';
