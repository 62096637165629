import type { PropsWithChildren } from 'react';
import { useCallback } from 'react';

import { UserContext } from '../contexts';
import { useWmgSession } from '../hooks/useWmgSession';

export function UserProvider({ children }: PropsWithChildren) {
  const { email, name, permissions } = useWmgSession();

  const hasPermission = useCallback(
    (permission: string, entityID?: string) => {
      // for unscoped permissions, we can use any entity, so we just take the first one
      const id = entityID || Object.keys(permissions)[0];
      return permissions[id]?.includes(permission) ?? false;
    },
    [permissions],
  );

  return (
    <UserContext.Provider
      value={{
        email,
        name,
        permissions,
        hasPermission,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

UserProvider.displayName = 'UserProvider';
