import type { DetailedHTMLProps, HTMLAttributes } from 'react';

import type {
  IconName,
  IconSize,
  IconVariant,
} from '~/components/Icon/types.ts';
import { mergeClasses } from '~/lib/mergeClasses';

interface IconProps
  extends Omit<
    DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>,
    'children'
  > {
  type: IconName;
  variant?: IconVariant;
  size?: IconSize;
}

export function Icon({
  size = 'lg',
  type,
  variant = 'solid',
  className,
  ...props
}: IconProps) {
  const fullVariant = `fa-${variant}`;
  const fullName = `fa-${type}`;
  const fullSize = `fa-${size}`;
  return (
    <i
      className={mergeClasses(fullVariant, fullName, fullSize, className)}
      role="img"
      {...props}
    />
  );
}

Icon.displayName = 'Icon';
