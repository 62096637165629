import { useTranslation } from 'react-i18next';

export function Header() {
  const { t } = useTranslation('roster');
  return (
    <header className="items-center gap-md my-3xl">
      <h1 className="py-sm text-display-lg font-bold">{t('header.title')}</h1>

      <h2 className="text-white font-medium">{t('header.subtitle')} (2342)</h2>
    </header>
  );
}

Header.displayName = 'Header';
