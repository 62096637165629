import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { mergeClasses } from '~/lib/mergeClasses';

export function BreadcrumbsItem({
  to,
  children,
  className,
  ...delegatedProps
}: Partial<LinkProps>) {
  const sharedClasses = mergeClasses('inline-block', 'text-body-sm', className);

  if (!to) {
    return (
      <span
        aria-current="page"
        className={`text-default ${sharedClasses}`}
        {...delegatedProps}
      >
        {children}
      </span>
    );
  }

  return (
    <Link to={to} className={sharedClasses} {...delegatedProps}>
      {children}
    </Link>
  );
}
BreadcrumbsItem.displayName = 'BreadcrumbsItem';
