import type React from 'react';
import { useMemo, useState } from 'react';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { Icon } from '~/components/Icon';
import { mergeClasses } from '~/lib/mergeClasses';

interface HoverIconItemProps
  extends Omit<LinkProps, 'onMouseEnter' | 'onMouseLeave'> {
  icon: string;
  text: string;
  'data-testid'?: string;
}

export const HoverIconItem: React.FC<HoverIconItemProps> = ({
  icon,
  text,
  className,
  'data-testid': dataTestId,
  to,
  ...props
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const isExternal = typeof to === 'string' && /^https?:\/\//.test(to);
  const sharedClasses = mergeClasses(
    'text-default',
    'no-underline',
    'text-caption-md',
    'flex',
    'items-center',
    'cursor-pointer',
    className,
  );
  const sharedContent = useMemo(
    () => (
      <>
        <Icon
          type={icon}
          variant={isHovered ? 'solid' : 'regular'}
          className="pr-sm"
          data-testid={dataTestId}
        />
        <span className={isHovered ? 'underline' : ''}>{text}</span>
      </>
    ),
    [dataTestId, icon, isHovered, text],
  );

  if (isExternal) {
    return (
      <a
        className={sharedClasses}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        href={to}
        {...props}
      >
        {sharedContent}
      </a>
    );
  }

  return (
    <Link
      className={sharedClasses}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      to={to}
      {...props}
    >
      {sharedContent}
    </Link>
  );
};

HoverIconItem.displayName = 'HoverIconItem';
