import { useOktaAuth } from '@okta/okta-react';
import {
  type PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { convertCoopLocale } from '~/i18n';
import { env } from '~/lib/env';
import { BrandContext } from '~/modules/branding';
import { useShowNav } from '~/modules/navigation';

import { ErrorPage as ErrorScreen } from '../../../components/ErrorPage/ErrorPage';
import { LoadingScreen } from '../../navigation/components/LoadingScreen';
import { bffClient, type UserResponse } from '../lib/bff-client';
import { WmgSessionContext } from './WmgSessionContext';

const WmgSessionProvider = ({ children }: PropsWithChildren) => {
  const { authState, oktaAuth } = useOktaAuth();
  const brand = useContext(BrandContext);
  const [error, setError] = useState<string | undefined>();
  const [user, setUser] = useState<UserResponse | undefined>();
  const { setShowNav, setShowByLocale } = useShowNav();
  const [isTokenRenew, setIsTokenRenew] = useState(false);

  const useContainerNav =
    env('VITE_USE_CONTAINER_NAVIGATION', false) === 'true';
  const bffGracefulFallback =
    env('VITE_BFF_GRACEFUL_FALLBACK', false) === 'true';
  const [authAttempted, setAuthAttempted] = useState(false);

  const token = authState?.accessToken?.accessToken;
  const oktaUserId = authState?.accessToken?.claims?.uid as string;

  const authenticateUser = useCallback(async () => {
    if (!token || !oktaUserId) {
      return;
    }

    setError(undefined);

    if (useContainerNav) {
      try {
        const fetchedUser = await bffClient.getUser(token);
        const fetchUserLocale = await bffClient.getUserLocale(
          token,
          oktaUserId,
        );
        setUser(fetchedUser);
        setShowByLocale(convertCoopLocale(fetchUserLocale));
      } catch (e) {
        if (isTokenRenew) {
          console.error('failed to reauthenticate');
          window.location.reload();
        }

        if (bffGracefulFallback) {
          setShowNav(false);
        } else {
          setError((e as Error).message || 'Unexpected error');
          setShowNav(true);
        }
      } finally {
        setAuthAttempted(true);
        setIsTokenRenew(false);
      }
    } else {
      setShowNav(false);
      setAuthAttempted(true);
    }
  }, [
    token,
    oktaUserId,
    useContainerNav,
    setShowByLocale,
    isTokenRenew,
    setShowNav,
    bffGracefulFallback,
  ]);

  useEffect(() => {
    const onTokenRenewed = async (key: string) => {
      if (key === 'accessToken') {
        setIsTokenRenew(true);

        await authenticateUser();
      }
    };
    oktaAuth.tokenManager.on('renewed', onTokenRenewed);

    return () => {
      oktaAuth.tokenManager.off('renewed', onTokenRenewed);
    };
  }, [authenticateUser, oktaAuth]);

  useEffect(() => {
    // Only authenticate if the brand is ADA until we have bff support for WMG
    if (brand.name === 'ADA') {
      authenticateUser();
    } else if (brand.name === 'WMG') {
      setUser({ permissions: {}, name: authenticateUser.name, email: '' });
      setShowByLocale('EN');
      setShowNav(true);
    }
  }, [authenticateUser, token, oktaUserId, brand, setShowByLocale, setShowNav]);

  if (error) {
    return <ErrorScreen message={error} onRetry={() => authenticateUser()} />;
  }

  if (
    authState?.accessToken?.accessToken &&
    !user &&
    useContainerNav &&
    !authAttempted
  ) {
    return <LoadingScreen />;
  }

  const wmgUser = user ?? {
    permissions: {},
    name: '',
    email: '',
  };

  return (
    <WmgSessionContext.Provider value={{ user: wmgUser!, token: token ?? '' }}>
      {children}
    </WmgSessionContext.Provider>
  );
};

WmgSessionProvider.displayName = 'WmgSessionProvider';

export { WmgSessionContext, WmgSessionProvider };
