import { Slot } from '@radix-ui/react-slot';
import type { ComponentProps } from 'react';

import { mergeClasses } from '~/lib/mergeClasses';
import type { VariantProps } from '~/lib/variants';
import { variants } from '~/lib/variants';

const tag = variants({
  base: [
    'rounded-xs',
    'text-strong',
    'transition-colors',
    'select-none',
    'items-baseline',
    'inline-flex',
    'items-center',
  ],
  variants: {
    variant: {
      primary: [
        'bg-primary-default',
        'hover:bg-primary-hover',
        'focus:bg-primary-pressed',
      ],
      secondary: [
        'outline',
        'outline-1',
        'outline-emphasis-subtle',
        'bg-emphasis-default',
        'hover:bg-emphasis-strong',
        'hover:outline-emphasis-stronger',
        'focus:bg-emphasis-strong',
        'focus:outline-emphasis-strong',
        'focus:outline-1',
      ],
    },
    size: {
      lg: ['px-sm', 'py-xs', 'text-body-sm', 'gap-sm', 'leading-tight'],
      sm: ['px-sm', 'py-xs', 'text-caption-md', 'gap-xs', 'leading-none'],
    },
    disabled: {
      true: [
        'cursor-not-allowed',
        'pointer-events-none',
        'bg-emphasis-default',
      ],
    },
  },
  compoundVariants: [
    {
      disabled: true,
      variant: 'primary',
      size: ['lg', 'sm'],
      class: ['text-subtle'],
    },
    {
      disabled: true,
      variant: 'secondary',
      size: ['lg', 'sm'],
      class: ['text-disabled'],
    },
  ],
});

type TagVariants = VariantProps<typeof tag>;

type TagProps = TagVariants &
  ComponentProps<'span'> & {
    asChild?: boolean;
  };

function Tag({
  variant = 'primary',
  size = 'lg',
  disabled,
  className,
  children,
  asChild = false,
  ...props
}: TagProps) {
  const Component = asChild ? Slot : 'span';

  return (
    <Component
      className={mergeClasses(tag({ size, variant, disabled }), className)}
      {...props}
    >
      {children}
    </Component>
  );
}

Tag.displayName = 'Tag';

export { Tag };
