import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Tag } from '~/components';
import { SETTINGS_ROOT_LINK } from '~settings/constants';
import type { LabelDetailed } from '~settings/types';

import { HeaderSmall } from '../components/HeaderSmall';
import { PropertyList } from '../components/PropertyList';

type DetailsKeys = keyof Pick<
  LabelDetailed,
  'weaLabelCode' | 'dealOwner' | 'dealRegionScope' | 'type' | 'parentName'
>;

const translationsMap: Record<DetailsKeys, string> = {
  weaLabelCode: 'weaLabelCode',
  dealOwner: 'dealOwner',
  dealRegionScope: 'dealScope',
  type: 'labelType',
  parentName: 'parentLabel',
};

export const LabelMetadata = ({ data }: { data: LabelDetailed }) => {
  const { t } = useTranslation('settings');

  // todo: create map of items to show based on external/internal user type
  const keysToRender: DetailsKeys[] = useMemo(
    () =>
      (Object.keys(translationsMap) as DetailsKeys[]).filter(
        (k) => data[k] !== undefined && data[k] !== null,
      ),
    [data],
  );

  return (
    <div>
      <HeaderSmall>{t('details')}</HeaderSmall>
      <PropertyList.Root>
        {keysToRender.map((key) => (
          <PropertyList.Item key={key}>
            <PropertyList.Key>{t(translationsMap[key])}</PropertyList.Key>
            <PropertyList.Value>
              {key === 'parentName' ? (
                <Tag variant="secondary" size="sm" asChild>
                  <Link to={`${SETTINGS_ROOT_LINK}/labels/${data.parentId}`}>
                    {data.parentName}
                  </Link>
                </Tag>
              ) : (
                data[key]
              )}
            </PropertyList.Value>
          </PropertyList.Item>
        ))}
      </PropertyList.Root>
    </div>
  );
};

LabelMetadata.displayName = 'LabelMetadata';
