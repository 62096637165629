const Spinner = () => {
  return (
    <div
      className="border-xs border-primary-disabled border-t-primary-default aspect-square w-2xl rounded-full animate-spin"
      data-testid="spinner"
    />
  );
};
Spinner.displayName = 'Spinner';

export { Spinner };
