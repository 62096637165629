import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { Table } from '~settings/components/Table';
import { useBffClient } from '~settings/hooks/useBffClient';
import { BffAction } from '~settings/modules/types';

import { Toolbar } from '../components/Toolbar';
import { columns } from './columns.config';

export const Users = () => {
  const fetchUsers = useBffClient(BffAction.FETCH_USERS);
  const { t } = useTranslation('settings');
  const { data, isLoading } = useQuery({
    queryKey: ['users'],
    queryFn: fetchUsers,
  });

  if (isLoading) {
    return <section className="py-md">Loading...</section>;
  }

  const users = data?.data ?? [];

  return (
    <>
      <Toolbar placeholder={t('searchUsers')} />
      <Table data={users} columns={columns} type="users" />
    </>
  );
};

Users.displayName = 'Users';
