import type React from 'react';

import { Icon } from '~/components/Icon';

interface ToolbarProps {
  placeholder: string;
}

export const Toolbar: React.FC<ToolbarProps> = ({ placeholder }) => {
  return (
    <div className="flex gap-md py-lg">
      <button className="flex items-center justify-center p-[0.625rem] bg-level-03 rounded-sm">
        <Icon type="filter" variant="solid" size="sm" className="p-sm" />
      </button>
      <div className="p-[0.625rem] flex flex-row bg-level-03 w-full gap-xs rounded-sm">
        <Icon
          type="search"
          size="md"
          className="flex justify-center px-sm py-sm items-center align-center"
        />
        <input
          placeholder={placeholder}
          type="search"
          className="bg-transparent w-full text-body-sm italic placeholder:text-disabled"
          aria-controls="tool-bar-search"
        />
      </div>
    </div>
  );
};

Toolbar.displayName = 'Toolbar';
