import { createContext } from 'react';

import { type UserResponse } from '../lib/bff-client';

export type WmgSession = {
  user: UserResponse;
  token: string;
};

const WmgSessionContext = createContext<WmgSession>({
  user: {
    permissions: {},
    name: '',
    email: '',
  },
  token: '',
});

WmgSessionContext.displayName = 'WmgSessionContext';

export { WmgSessionContext };
