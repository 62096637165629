import { BffAction } from '~settings/modules/types';
import {
  transformLabelDetails,
  transformLabels,
  transformUsers,
} from '~settings/utils/transformers';

const getBasicParams = (token: string): RequestInit => ({
  method: 'GET',
  credentials: 'include',
  headers: {
    Authorization: `Bearer ${token}`,
    'x-no-gzip-response': 'true',
  },
});

const bffClient = {
  [BffAction.FETCH_USERS]: async (token: string) => {
    try {
      const response = await fetch(
        '/api/coop2/users?&skip=0&take=50',
        getBasicParams(token),
      );

      const data = await response.json();

      return {
        ...data,
        data: transformUsers(data.data),
      };
    } catch (error) {
      console.error(error);
    }
  },
  [BffAction.FETCH_LABELS]: async (token: string) => {
    try {
      const response = await fetch(
        `/api/coop2/labels?&skip=0&take=50`,
        getBasicParams(token),
      );
      const data = await response.json();

      return {
        ...data,
        data: transformLabels(data.data),
      };
    } catch (error) {
      console.error(error);
    }
  },
  [BffAction.GET_LABEL_DETAILS]: async (token: string, uuid: string) => {
    try {
      const response = await fetch(
        `/api/coop2/labels/${uuid}`,
        getBasicParams(token),
      );
      const data = await response.json();

      return transformLabelDetails(data);
    } catch (error) {
      console.error(error);
    }
  },
};

export { bffClient };
