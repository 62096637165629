import { variables } from './variables.ts';

/**
 * Type-safe constrain to enforce an environment variable to be
 * available and return its value, or throw otherwise.
 */
const env = <
  K extends keyof typeof variables,
  B extends boolean | undefined = undefined,
  R = B extends false ? string | undefined : string,
>(
  name: K,
  enforce?: B,
): R => {
  if (enforce !== false && typeof variables[name] === 'undefined') {
    throw new Error(`Missing ${name} environment variable`);
  }

  return variables[name] as R;
};

export { env };
