import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

type UseSelectedProps = {
  items: Array<{ to: string; matchRoutes?: RegExp[] }>;
};

/**
 * Returns the index of the selected item based on the current location
 * @param items list of items that can be selected
 * @returns index of the selected item or undefined if none is matching
 */
export const useSelected = ({ items }: UseSelectedProps) => {
  const location = useLocation();
  const [selected, setSelected] = useState<number | undefined>(0);
  const refToBlur = useRef<HTMLElement | null>(null);

  // This effect is used to highlight the active link in the sidebar
  useEffect(() => {
    // Modals have higher priority than routes
    if (location.hash !== '') {
      const index = items.findIndex((link) => link.to === location.hash);

      if (index !== -1) {
        setSelected(index);
        return;
      }
    }

    let foundIndex = items.findIndex((link) => link.to === location.pathname);

    // Check if the current route matches any of the matchRoutes
    if (foundIndex === -1) {
      foundIndex = items.findIndex(
        (link) =>
          link.matchRoutes?.some((route) => route.test(location.pathname)) ??
          false,
      );
    }

    // Fallback to the first match
    if (foundIndex === -1) {
      // Ignore the root route as that would always be selected
      foundIndex = items.findIndex(
        (link) => link.to !== '/' && location.pathname.startsWith(link.to),
      );
    }

    setSelected(foundIndex !== -1 ? foundIndex : undefined);
  }, [items, location]);

  useEffect(() => {
    refToBlur.current?.blur();
  }, [selected]);

  return { selected, refToBlur };
};
