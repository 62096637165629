import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const WelcomeLogo: FC = () => {
  const { t } = useTranslation('wmgone');

  return (
    <div className="flex flex-col w-[21em] gap-[1.5em]">
      <div className="font-dazzed font-bold text-[7em] text-tertiary-default">
        <h2>WMG</h2>
        <h2 className="-mt-4xl">ONE</h2>
      </div>
      <p className="text-headline-md text-white font-semibold">
        {t('static-page.hero.title')}
      </p>
      <p className="text-body-md text-white font-extralight w-[18em]">
        {t('static-page.hero.description')}
      </p>
    </div>
  );
};

WelcomeLogo.displayName = 'WelcomeLogo';
