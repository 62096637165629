const getBasicParams = (token: string): RequestInit => ({
  method: 'GET',
  credentials: 'include',
  headers: {
    Authorization: `Bearer ${token}`,
    'x-no-gzip-response': 'true',
  },
});

export const makeRequest = async (url: RequestInfo | URL, token: string) => {
  const response = await fetch(url, getBasicParams(token));

  if (!response.ok) {
    throw new Error('Failed to fetch data');
  }

  return response.json();
};
