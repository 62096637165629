import * as RadixPopover from '@radix-ui/react-popover';
import type { MouseEvent, ReactNode } from 'react';
import React from 'react';

type ContentType =
  | ReactNode
  | ((onItemClick: (event?: MouseEvent<HTMLElement>) => void) => ReactNode);

type PopoverProps = {
  content: ContentType;
  children: ReactNode;
  disabled?: boolean;
};

export const Popover = ({ content, children, disabled }: PopoverProps) => {
  const [isOpened, setIsOpened] = React.useState(false);

  const closePopover = () => {
    setIsOpened(false);
  };

  const ContentComponent =
    typeof content === 'function' ? content(closePopover) : content;

  return (
    <RadixPopover.Root open={isOpened} onOpenChange={setIsOpened}>
      <RadixPopover.Anchor />
      <RadixPopover.Trigger disabled={disabled}>
        {children}
      </RadixPopover.Trigger>
      <RadixPopover.Portal>
        <RadixPopover.Content side="right" sideOffset={5} className="z-50">
          {ContentComponent}
        </RadixPopover.Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  );
};

Popover.displayName = 'Popover';
