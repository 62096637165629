import type { IconName, IconVariant } from '~/components/Icon';
import { NAVIGATION_PERMISSIONS } from '~/lib/navigationPermissions.ts';
import type { HasPermissionFunction } from '~/modules/remotes/types.ts';
import { BRANDS, type BrandType } from '~/shared/lib/brands';

type NavItem = {
  to: string;
  icon: IconName;
  iconVariant?: IconVariant;
  label: string;
  brands?: BrandType[];

  /**
   * Additional routes that will be considered as active when the current route matches.
   */
  matchRoutes?: RegExp[];
  title?: string;
  permission?: (
    hasPermission: HasPermissionFunction,
    context?: Record<string, unknown>,
  ) => boolean;
};

export const SIDEBAR_NAV_ITEMS: NavItem[] = [
  {
    to: '/',
    icon: 'list-music',
    label: 'dashboard',
    title: 'dashboard',
    brands: [BRANDS.ADA],
  },
  {
    to: '#bulk_upload_modal',
    matchRoutes: [/uploads/],
    icon: 'folder-arrow-up',
    label: 'bulkUpload',
    title: 'bulkUpload',
    permission: NAVIGATION_PERMISSIONS.BULK_UPLOAD_MODAL,
    brands: [BRANDS.ADA],
  },
  {
    to: '/release-clinic',
    icon: 'truck-medical',
    label: 'releaseClinic',
    title: 'releaseClinic',
    permission: NAVIGATION_PERMISSIONS.RELEASE_CLINIC,
    brands: [BRANDS.ADA],
  },
  {
    to: '/video-management',
    icon: 'clapperboard-play',
    label: 'video',
    title: 'video',
    permission: NAVIGATION_PERMISSIONS.VIDEO_MANAGEMENT,
    brands: [BRANDS.ADA],
  },
  {
    to: '/reports',
    icon: 'chart-mixed',
    label: 'reports',
    title: 'reports',
    permission: NAVIGATION_PERMISSIONS.REPORTS,
    brands: [BRANDS.ADA],
  },
  {
    to: '/release/settings/core-details',
    icon: 'file-music',
    label: 'createRelease',
    title: 'createRelease',
    permission: NAVIGATION_PERMISSIONS.CREATE_RELEASE,
    brands: [BRANDS.ADA],
  },
  {
    to: '/platform/settings',
    icon: 'gear',
    label: 'settings',
    title: 'settings',
    permission: NAVIGATION_PERMISSIONS.COOP_SETTINGS,
    brands: [BRANDS.ADA],
  },
  {
    to: '/settings',
    icon: 'user-gear',
    label: 'settingsNew',
    title: 'settingsNew',
    permission: NAVIGATION_PERMISSIONS.SETTINGS,
    brands: [BRANDS.ADA],
  },

  {
    to: '/',
    icon: 'home',
    iconVariant: 'light',
    label: 'wmgHome',
    title: 'wmgHome',
    brands: [BRANDS.WMG],
  },
  {
    to: '/marketing',
    icon: 'calendar',
    iconVariant: 'light',
    label: 'marketing',
    title: 'marketing',
    brands: [BRANDS.WMG],
  },
  {
    to: '/data',
    icon: 'chart-mixed',
    iconVariant: 'light',
    label: 'data',
    title: 'data',
    brands: [BRANDS.WMG],
  },
  {
    to: '/distribution',
    icon: 'list-music',
    iconVariant: 'light',
    label: 'distribution',
    title: 'distribution',
    brands: [BRANDS.WMG],
  },
  {
    to: '/publishing',
    icon: 'file-music',
    iconVariant: 'light',
    label: 'publishing',
    title: 'publishing',
    brands: [BRANDS.WMG],
  },
  {
    to: '/finance',
    icon: 'circle-dollar',
    iconVariant: 'light',
    label: 'finance',
    title: 'finance',
    brands: [BRANDS.WMG],
  },
];
