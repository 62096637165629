import { useParams } from 'react-router-dom';

import { ContentBox } from '~settings/components/ContentBox';
import { useUserDetails } from '~settings/hooks/useUserDetails';
import { UserLabels } from '~settings/UserDetails/UserLabels';

import { UserDetailsBlock } from './parts/UserDetailsBlock';
import { UserHeader } from './parts/UserHeader';

export const UserDetails = () => {
  const { uuid } = useParams();
  const { data, isLoading, isError } = useUserDetails(uuid);

  if (isLoading) {
    return <section className="py-md">Loading...</section>;
  }

  if (isError || !data) {
    // eslint-disable-next-line i18next/no-literal-string
    return <>Failed to fetch user details</>;
  }

  return (
    <>
      <UserHeader name={data!.fullName} />

      <article className="py-md flex gap-md">
        <section className="text-body-sm flex-none basis-[20%] min-w-[19rem] inline-flex flex-col gap-md">
          <UserDetailsBlock user={data!} />
        </section>
        <ContentBox className="flex-1 rounded-sm py-lg">
          <UserLabels labels={data.labels} />
        </ContentBox>
      </article>
    </>
  );
};

UserDetails.displayName = 'UserDetails';
