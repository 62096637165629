import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { ArtistPageLayout } from '~/modules/artist-roster/components/ArtistPageLayout.tsx';
import { ArtistTable } from '~/modules/artist-roster/components/ArtistTable.tsx';

/**
 * Top-level router combining module-level routers
 */
const Router = () => {
  return (
    <Suspense>
      <Routes>
        <Route element={<ArtistPageLayout />}>
          <Route path="*" element={<ArtistTable />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

Router.displayName = 'RosterRouter';

export { Router };
