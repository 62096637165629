import './styles/main.css';
import '../public/fonts/fontawesome/all.min.css';

import React from 'react';
import ReactDOM from 'react-dom/client';

import { startMonitoring } from '~/modules/monitoring';

import { App } from './App.tsx';

startMonitoring();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
