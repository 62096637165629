import type { PropsWithChildren } from 'react';

export const AuthLayout = ({ children }: PropsWithChildren) => {
  return (
    <div className="w-full bg-brand bg-no-repeat min-h-screen flex justify-center md:justify-start items-center md:px-2xl">
      {children}
    </div>
  );
};

AuthLayout.displayName = 'AuthLayout';
