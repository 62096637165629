import { useTranslation } from 'react-i18next';

import { Separator, Tag } from '~/components';

import { HeaderSmall } from '../components/HeaderSmall';
import type { LabelDetailed } from '../types';

export const LabelGroups = ({ data }: { data: LabelDetailed }) => {
  const { t } = useTranslation('settings');

  if (!data.groupsNames?.length) return null;
  return (
    <div>
      <Separator className="my-lg" />
      <HeaderSmall>{t('labelGroups')}</HeaderSmall>
      <ul className="flex flex-wrap gap-y-sm gap-x-xs">
        {data.groupsNames.map((group) => (
          <Tag key={group} variant="secondary" size="lg" asChild>
            <li>
              <span>{group}</span>
            </li>
          </Tag>
        ))}
      </ul>
    </div>
  );
};

LabelGroups.displayName = 'LabelGroups';
