import type { FC, PropsWithChildren } from 'react';
import { Fragment, memo, useContext } from 'react';

import { BrandContext } from '~/modules/branding';

const ThemeProviderComponent: FC<PropsWithChildren> = ({ children }) => {
  const { meta } = useContext(BrandContext);

  return (
    <Fragment>
      {meta}
      {children}
    </Fragment>
  );
};

ThemeProviderComponent.displayName = 'ThemeProviderComponent';

export const ThemeProvider = memo(ThemeProviderComponent);
