export type ArtistDataType = {
  id: number;
  name: string;
  label: string;
  quickJump: string[];
  social: { name: string; url: string }[];
};

export const getArtists = async (
  err: boolean = false,
  search: string = '',
): Promise<ArtistDataType[]> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (err) {
        reject(new Error('Something went wrong...'));
      } else {
        resolve(
          mockArtistData.filter((artist) =>
            artist.name.toLowerCase().includes(search.toLowerCase()),
          ),
        );
      }
    }, 1000);
  });
};

const mockArtistData: ArtistDataType[] = [
  {
    id: 1,
    name: 'John Doe',
    label: 'Universal Music',
    quickJump: ['Opus', 'Sodatone', 'Artist Packs'],
    social: [
      { name: 'instagram', url: 'https://www.instagram.com/johndoe' },
      {
        name: 'spotify',
        url: 'https://open.spotify.com/artist/1',
      },
      {
        name: 'youtube',
        url: 'https://www.youtube.com/channel/1',
      },
      {
        name: 'tiktok',
        url: 'https://www.tiktok.com/@johndoe',
      },
    ],
  },
  {
    id: 2,
    name: 'Jane Doe',
    label: 'Sony Music',
    quickJump: ['Opus', 'Sodatone', 'Artist Packs'],
    social: [
      { name: 'instagram', url: 'https://www.instagram.com/janedoe' },
      {
        name: 'spotify',
        url: 'https://open.spotify.com/artist/2',
      },
      {
        name: 'youtube',
        url: 'https://www.youtube.com/channel/2',
      },
      {
        name: 'tiktok',
        url: 'https://www.tiktok.com/@janedoe',
      },
    ],
  },
  {
    id: 3,
    name: 'Jack Doe',
    label: 'Warner Music',
    quickJump: ['Opus', 'Sodatone', 'Artist Packs'],
    social: [
      { name: 'instagram', url: 'https://www.instagram.com/jackdoe' },
      {
        name: 'spotify',
        url: 'https://open.spotify.com/artist/3',
      },
      {
        name: 'youtube',
        url: 'https://www.youtube.com/channel/3',
      },
      {
        name: 'tiktok',
        url: 'https://www.tiktok.com/@jackdoe',
      },
    ],
  },
  {
    id: 4,
    name: 'Jill Doe',
    label: 'Warner Music',
    quickJump: ['Opus', 'Sodatone', 'Artist Packs'],
    social: [
      { name: 'instagram', url: 'https://www.instagram.com/jilldoe' },
      {
        name: 'spotify',
        url: 'https://open.spotify.com/artist/4',
      },
      {
        name: 'youtube',
        url: 'https://www.youtube.com/channel/4',
      },
      {
        name: 'tiktok',
        url: 'https://www.tiktok.com/@jilldoe',
      },
    ],
  },
  {
    id: 5,
    name: 'Jim Doe',
    label: 'Warner Music',
    quickJump: ['Opus', 'Sodatone', 'Artist Packs'],
    social: [
      { name: 'instagram', url: 'https://www.instagram.com/jimdoe' },
      {
        name: 'spotify',
        url: 'https://open.spotify.com/artist/5',
      },
      {
        name: 'youtube',
        url: 'https://www.youtube.com/channel/5',
      },
      {
        name: 'tiktok',
        url: 'https://www.tiktok.com/@jimdoe',
      },
    ],
  },
  {
    id: 6,
    name: 'Jenny Doe',
    label: 'Sony Music',
    quickJump: ['Opus', 'Sodatone', 'Artist Packs'],
    social: [
      { name: 'instagram', url: 'https://www.instagram.com/jennydoe' },
      {
        name: 'spotify',
        url: 'https://open.spotify.com/artist/6',
      },
      {
        name: 'youtube',
        url: 'https://www.youtube.com/channel/6',
      },
      {
        name: 'tiktok',
        url: 'https://www.tiktok.com/@jennydoe',
      },
    ],
  },
  {
    id: 7,
    name: 'Jared Doe',
    label: 'Universal Music',
    quickJump: ['Opus', 'Sodatone', 'Artist Packs'],
    social: [
      { name: 'instagram', url: 'https://www.instagram.com/jareddoe' },
      {
        name: 'spotify',
        url: 'https://open.spotify.com/artist/7',
      },
      {
        name: 'youtube',
        url: 'https://www.youtube.com/channel/7',
      },
      {
        name: 'tiktok',
        url: 'https://www.tiktok.com/@jareddoe',
      },
    ],
  },
  {
    id: 8,
    name: 'Jasmine Doe',
    label: 'Sony Music',
    quickJump: ['Opus', 'Sodatone', 'Artist Packs'],
    social: [
      { name: 'instagram', url: 'https://www.instagram.com/jasminedoe' },
      {
        name: 'spotify',
        url: 'https://open.spotify.com/artist/8',
      },
      {
        name: 'youtube',
        url: 'https://www.youtube.com/channel/8',
      },
      {
        name: 'tiktok',
        url: 'https://www.tiktok.com/@jasminedoe',
      },
    ],
  },
];
