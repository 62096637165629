import type { Row } from '@tanstack/react-table';

export interface BaseEntity {
  id: string;
  name: string;
}

export enum LabelType {
  PARENT_LABEL = 'Parent label',
  SUBLABEL = 'Sublabel',
}

export interface User {
  id: string;
  fullName: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  email: string;
  isExternal: boolean;
  labelGroups: string[];
  labelsNames: string[];
  lastLogin: string;
  roles: string[];
}

export interface UserLabel {
  id: string;
  name: string;
  isActive: boolean;
  type: LabelType;
  roles: string[];
  parent: BaseEntity | null;
  isParentLabel: boolean;
}

export interface UserDetails {
  id: string;
  fullName: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  email: string;
  phone: string;
  isExternal: boolean;
  lastLogin: string;
  labels: UserLabel[];
}
export type UserRow = Row<User>;

export interface Label {
  id: string;
  name: string;
  isActive: boolean;
  type: LabelType;
  usersCount?: number;
  sublabelsCount: number;
  groupsNames: string[];
}

export interface LabelDetailed extends Label {
  parentId: string | null;
  parentName: string | null;
  weaLabelCode: string;
  dealRegionScope: string;
  dealOwner: string;
  distributionGroups: Array<{
    id: string;
    name: string;
    distributionGroupCountries: Array<{
      country: {
        id: string;
        name: string;
      };
    }>;
  }>;
  sublabels: Label[];
  socials?: Array<[string, string]>;
  users: User[];
  adminUsers: User[];
}

export type LabelRow = Row<Label>;
