import type { PropsWithChildren } from 'react';
import { Component } from 'react';

import { logger } from '~/lib/logger';

class ErrorBoundary<
  T extends PropsWithChildren<{
    fallback?: PropsWithChildren<unknown>['children'];
    onError?: (error: Error) => void;
  }>,
> extends Component<T, { error?: string }> {
  static displayName = 'ErrorBoundary';
  constructor(props: T) {
    super(props);
    this.state = { error: undefined };
  }

  componentDidCatch(error: Error) {
    logger.error(error);
    this.setState({ error: `${error.name}: ${error.message}` });
    this.props.onError?.(error);

    const currentURL = window.location.hostname;
    const isStaging = currentURL.includes('staging');
    const isDev = currentURL.includes('dev');
    const isLocal = currentURL.includes('localhost');

    const isNetworkError = error.message.includes(
      'Failed to fetch dynamically imported module',
    );

    if (isNetworkError && (isDev || isLocal || isStaging)) {
      let environment = '';

      if (isStaging) {
        environment = 'staging';
      } else if (isDev) {
        environment = 'development';
      } else if (isLocal) {
        environment = 'local';
      }

      const errorrMessage = `It seems there is a connection issue in the ${environment} environment. Common issues may include VPN disconnection or misconfigured environemnt variables. Please verify your settings and try again.`;

      this.setState({ error: errorrMessage });
    } else if (isNetworkError) {
      const prodErrorMessage = `A connection issue has occured. Please check your network settings are try again.`;
      this.setState({ error: prodErrorMessage });
    }
  }

  render() {
    const { error } = this.state;
    if (error) {
      return this.props.fallback || <div>{error}</div>;
    } else {
      return <>{this.props.children}</>;
    }
  }
}

export { ErrorBoundary };
