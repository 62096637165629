import { lazy } from 'react';

import { NAVIGATION_PERMISSIONS } from '~/lib/navigationPermissions.ts';

import type { IRemoteDeclaration } from './types';

const remotes: IRemoteDeclaration[] = [
  {
    isolateStyles: true,
    name: 'video-management',
    path: ['video-management/*'],
    status: lazy(() => import('VideoApp/Status')),
    root: lazy(() => import('VideoApp/App')),
    prefix: 'video-management',
    permission: NAVIGATION_PERMISSIONS.VIDEO_MANAGEMENT,
  },
  {
    isolateStyles: true,
    name: 'coop',
    path: [
      '*',
      'release',
      'my-profile',
      'platform',
      'release-clinic',
      'uploads',
      'redirect',
      'reports',
      'report',
      'my-permissions',
      'preferences',
      'ingestions',
    ],
    status: lazy(() => import('CoopApp/Status')),
    root: lazy(() => import('CoopApp/App')),
    permission: () => true,
  },
];

export { remotes };
