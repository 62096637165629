import { useQuery } from '@tanstack/react-query';

import { useWmgSession } from '~/modules/auth/hooks/useWmgSession.ts';
import type { LabelDetailed } from '~settings/types.ts';
import { makeRequest } from '~settings/utils/request.ts';
import { transformLabelDetails } from '~settings/utils/transformers';

export const useLabelDetails = (uuid: string | undefined) => {
  const { token } = useWmgSession();

  return useQuery({
    queryKey: ['labels', 'details', uuid],
    queryFn: async (): Promise<LabelDetailed> => {
      const data = await makeRequest(`/api/coop2/labels/${uuid}`, token);

      return transformLabelDetails(data);
    },
    retry: 1,
  });
};
