import type React from 'react';
import { useCallback, useState } from 'react';

import { mergeClasses } from '~/lib/mergeClasses';

import { Icon } from '../Icon';

interface SearchBarProps {
  placeholder: string;
  onSubmit: (value: string) => void;
  className?: string;
}

const SearchBar = ({
  placeholder,
  onSubmit,
  className = '',
}: SearchBarProps) => {
  const [inputValue, setInputValue] = useState('');

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent): void => {
      if (event.key !== 'Enter') return;
      onSubmit(inputValue);
    },
    [onSubmit, inputValue],
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setInputValue(event.target.value);
    },
    [],
  );

  return (
    <div
      className={mergeClasses(
        'flex',
        'bg-alpha-default-emphasis',
        'outline',
        'outline-1',
        'outline-emphasis-subtle',
        'w-full',
        'py-sm',
        'px-sm',
        'gap-sm',
        'rounded-sm',
        'focus-within:bg-alpha-strong-emphasis',
        'focus-within:outline-emphasis-stronger',
        'hover:bg-alpha-strong-emphasis',
        'hover:outline-emphasis-stronger',
        className,
      )}
    >
      <Icon
        className={mergeClasses(
          inputValue ? 'text-default' : 'text-subtle',
          'py-md',
          'px-sm',
        )}
        type="search"
        size="sm"
      />
      <input
        className={mergeClasses(
          'bg-transparent',
          'focus:ring-none',
          'focus:outline-none',
          'flex-grow',
          'italic',
          'text-default',
          'text-body-sm',
        )}
        placeholder={placeholder}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};

SearchBar.displayName = 'SearchBar';

export { SearchBar };
