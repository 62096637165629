import { Slot } from '@radix-ui/react-slot';
import type { ReactNode } from 'react';
import type React from 'react';

import { variants } from '~/lib/variants.ts';

type Size = 'xlarge' | 'large' | 'medium' | 'small';
type Variant = 'primary' | 'secondary' | 'tertiary';

const button = variants({
  base: [
    'inline-block',
    'leading-tight',
    'disabled:pointer-events-none',
    'font-medium',
    '[&_i]:text-body-sm',
  ],
  variants: {
    variant: {
      primary: [
        'text-strong-on-dark',
        'bg-primary-default',
        'hover:bg-primary-hover',
        'focus:bg-primary-pressed',
        'active:bg-primary-pressed',
        'disabled:text-disabled',
        'disabled:bg-primary-disabled',
        'rounded-full',
      ],
      secondary: [
        'text-strong',
        'bg-transparent',
        'rounded-full',
        'outline',
        'outline-1',
        'outline-default',
        'hover:outline-hover',
        'hover:bg-emphasis-strong',
        'active:outline-pressed',
        'focus:outline-pressed',
        'disabled:text-disabled',
        'disabled:outline-disabled',
      ],
      tertiary: ['text-strong-on-dark', 'underline', 'disabled:text-disabled'],
    },
    size: {
      xlarge: ['py-md', 'px-xl'],
      large: ['py-sm', 'px-md'],
      medium: ['py-xs', 'px-md'],
      small: ['py-xs', 'px-md', 'text-body-sm', '[&_i]:text-body-xs'],
    },
  },
  compoundVariants: [
    {
      variant: 'tertiary',
      size: ['xlarge', 'large', 'medium', 'small'],
      class: ['p-0'],
    },
  ],
});

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  variant?: Variant;
  size?: Size;
  className?: string;
  asChild?: boolean;
}

const Button = ({
  children,
  variant = 'primary',
  size = 'medium',
  className = '',
  asChild = false,
  ...props
}: ButtonProps) => {
  const styles = button({ variant, size });
  const Component = asChild ? Slot : 'button';

  return (
    <Component {...props} className={`${className} ${styles}`}>
      {children}
    </Component>
  );
};

Button.displayName = 'Button';

export { Button };
