import { Trans, useTranslation } from 'react-i18next';

import { BrandLogo } from '~/modules/branding/components/BrandLogo';

interface ErrorPageProps {
  type?: 'default' | 'noAccessPage' | 'notFoundPage';
  message?: string;
  onRetry?: () => void;
}

const ErrorPage = ({ type = 'default', message, onRetry }: ErrorPageProps) => {
  const { t } = useTranslation('errors');

  return (
    <div className="absolute top-0 left-0 flex flex-col justify-center w-full h-full bg-default">
      <span className="w-full flex flex-row items-center h-auto py-lg pl-md text-primary-default">
        <BrandLogo className="h-lg" />
      </span>
      <div className="h-full w-full bg-gradient flex marker:flex-col items-center bg-cover bg-no-repeat bg-bottom whitespace-break-spaces">
        <div className="ml-3xl">
          <div className="mb-lg" data-testid="Error Banner">
            <p className="font-header text-display-5xl m-0 leading-none uppercase">
              <Trans
                t={t}
                i18nKey={`${type}.header`}
                components={{ br: <br /> }}
              />
            </p>
            <p className="text-body-md mt-sm">{t(`${type}.description`)}</p>
            {message && <p className="text-body-sm mt-sm">{message}</p>}
          </div>
          {onRetry ? (
            <button
              className="py-sm px-md text-default bg-primary-default hover:bg-primary-hover focus:bg-primary-hover active:bg-primary-pressed disabled:text-disabled disabled:bg-primary-disabled rounded-full"
              onClick={onRetry}
            >
              {t(`${type}.retryButton`)}
            </button>
          ) : (
            <a href="/">
              <button className="py-sm px-md text-default bg-primary-default hover:bg-primary-hover focus:bg-primary-hover active:bg-primary-pressed disabled:text-disabled disabled:bg-primary-disabled rounded-full">
                {t(`${type}.retryButton`)}
              </button>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

ErrorPage.displayName = 'ErrorPage';

export { ErrorPage };
