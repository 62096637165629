import type { PropsWithChildren } from 'react';

type Props = PropsWithChildren & {
  className?: string;
};
export const ContentBox = ({ className = '', children }: Props) => (
  <div className={`bg-level-02 rounded-sm p-md ${className}`}>{children}</div>
);

ContentBox.displayName = 'ContentBox';
