export function Tiktok() {
  return (
    <svg
      role="img"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.875 7.63281C15.293 7.66797 13.8164 7.17578 12.5508 6.26172V12.5547C12.5508 16.9492 7.76953 19.6914 3.97266 17.5117C0.175781 15.2969 0.175781 9.8125 3.97266 7.59766C5.0625 6.96484 6.36328 6.71875 7.62891 6.89453V10.0586C5.69531 9.42578 3.83203 11.1484 4.25391 13.1172C4.71094 15.0859 7.10156 15.8594 8.61328 14.4883C9.14062 13.9961 9.45703 13.293 9.45703 12.5547V0.25H12.5508C12.5508 0.53125 12.5508 0.777344 12.6211 1.05859C12.832 2.21875 13.5 3.23828 14.5195 3.87109C15.1875 4.32812 16.0312 4.57422 16.875 4.57422V7.63281Z"
        fill="white"
      />
    </svg>
  );
}

Tiktok.displayName = 'Tiktok';
