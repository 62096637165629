import { useTranslation } from 'react-i18next';

import { Table } from '~settings/components/Table';
import { columns } from '~settings/Labels/columns.config';
import type { Label } from '~settings/types';

import { Toolbar } from '../components/Toolbar';

export const Sublabels = ({ sublabels }: { sublabels: Label[] }) => {
  const { t } = useTranslation('settings');
  return (
    <>
      <Toolbar placeholder={t('searchLabels')} />
      <Table
        data={sublabels}
        columns={columns}
        oddRowClassName="bg-level-03"
        type="labels"
      />
    </>
  );
};

Sublabels.displayName = 'Sublabels';
