import type { FC, PropsWithChildren } from 'react';

const Layout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <main className="grow p-xl bg-gradient bg-contain bg-no-repeat bg-level-01/100">
      {children}
    </main>
  );
};

Layout.displayName = 'Layout';

export { Layout };
