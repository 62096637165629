import type { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { useWmgSession } from '~/modules/auth/hooks/useWmgSession.ts';

import { LinkSearchTrigger } from '../LinkFarmSearch';

export const PageTemplate: FC<PropsWithChildren> = ({ children }) => {
  const { name } = useWmgSession();
  const { t } = useTranslation('wmgone');

  return (
    <div className="flex flex-col h-screen w-full p-[2em] gap-xl">
      <div className="flex w-full items-center justify-between">
        <p className="text-display-md text-white font-bold tracking-[0.031em]">
          {t('static-page.hero.hello')} {name}
        </p>
        <LinkSearchTrigger />
      </div>
      {children}
    </div>
  );
};

PageTemplate.displayName = 'PageTemplate';
