import { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { ErrorBoundary } from '~/modules/errors/components/ErrorBoundary';

import { remotes } from '../registry';

const RemotesStatus = () => {
  const { t } = useTranslation('remotes');

  return (
    <section>
      <Helmet>
        <title>{t('pageTitle')}</title>
      </Helmet>
      <h1>{t('header')}</h1>
      <table>
        <thead>
          <tr>
            <th>{t('tableHeaders.remoteApp')}</th>
            <th>{t('tableHeaders.status')}</th>
          </tr>
        </thead>
        <tbody>
          {remotes.map((remote) => (
            <tr key={remote.name}>
              <td>{remote.name}</td>
              <td>
                <ErrorBoundary
                  fallback={
                    <span className="bg-error-default rounded">
                      {t('statuses.down')}
                    </span>
                  }
                >
                  <Suspense>
                    <span className="bg-success-default rounded">
                      {t('statuses.operational')}
                    </span>
                    <remote.status />
                  </Suspense>
                </ErrorBoundary>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

RemotesStatus.displayName = 'RemotesStatus';

export { RemotesStatus };
