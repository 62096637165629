import type { ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  children: ReactNode;
}

export const Modal = ({ isOpen, onClose, title, children }: ModalProps) => {
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, setIsVisible] = useState(isOpen);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      setIsVisible(true);
      setTimeout(() => setIsAnimating(true), 10);
      document.body.style.overflow = 'hidden';
    } else {
      setIsAnimating(false);
      const timer = setTimeout(() => {
        setIsVisible(false);
        document.body.style.overflow = '';
      }, 150);
      return () => clearTimeout(timer);
    }

    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.body.style.overflow = '';
    };
  }, [isOpen, onClose]);

  if (!isVisible) return null;

  return createPortal(
    <div
      ref={overlayRef}
      role="button"
      tabIndex={0}
      className={`fixed inset-0 z-50 flex items-center justify-center cursor-default`}
    >
      <div
        className={`absolute inset-0 bg-overlay bg-transparent transition-filter duration-150 ${
          isAnimating ? 'backdrop-blur' : ''
        }`}
        aria-hidden="true"
      />
      <div
        className={`absolute inset-0 bg-overlay transition-opacity duration-150 ${
          isAnimating ? 'opacity-85' : 'opacity-0'
        }`}
        onClick={onClose}
        aria-hidden="true"
        data-testid="modal-overlay"
      />
      <div
        role="dialog"
        aria-labelledby="modal-title"
        aria-modal="true"
        className={`relative bg-transparent rounded-lg shadow-lg p-6 z-10 flex items-center justify-center flex-col  transition-opacity duration-150 ${
          isAnimating ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <header className="flex items-center justify-between mb-4 bg-transparent">
          {title && (
            <h2 id="modal-title" className="text-lg font-semibold text-default">
              {title}
            </h2>
          )}
        </header>

        {children}
      </div>
    </div>,
    document.body,
  );
};
Modal.displayName = 'Modal';
