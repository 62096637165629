import { useTranslation } from 'react-i18next';

import { Icon, Tooltip } from '~/components';
import { RelativeDate } from '~/modules/settings/components/RelativeDate';
import { formatPhoneNumber } from '~/modules/settings/utils/formatters';
import { ContentBox } from '~settings/components/ContentBox';
import { HeaderSmall } from '~settings/components/HeaderSmall';
import { PropertyList } from '~settings/components/PropertyList';
import type { UserDetails } from '~settings/types';

interface UserDetailsBlockProps {
  user: UserDetails;
}

export function UserDetailsBlock({ user }: UserDetailsBlockProps) {
  const { t } = useTranslation('settings');

  const formattedPhoneNumber = formatPhoneNumber(user.phone);

  return (
    <ContentBox>
      <HeaderSmall>{t('details')}</HeaderSmall>

      <PropertyList.Root>
        <PropertyList.Item>
          <PropertyList.Key>{t('email')}</PropertyList.Key>
          <PropertyList.Value>{user.email}</PropertyList.Value>
        </PropertyList.Item>
        {formattedPhoneNumber && (
          <PropertyList.Item>
            <PropertyList.Key>{t('phone')}</PropertyList.Key>
            <PropertyList.Value>{formattedPhoneNumber}</PropertyList.Value>
          </PropertyList.Item>
        )}
        <PropertyList.Item>
          <PropertyList.Key>{t('status')}</PropertyList.Key>
          <PropertyList.Value>
            {user.isActive ? t('active') : t('disabled')}
            <Tooltip
              content={t(user.isActive ? 'activeTooltip' : 'disabledTooltip')}
              side="top"
              delayDuration={0}
            >
              <Icon
                type="circle-info"
                variant="light"
                className="text-subtlest ml-sm"
                size="sm"
              />
            </Tooltip>
          </PropertyList.Value>
        </PropertyList.Item>
        <PropertyList.Item>
          <PropertyList.Key>{t('lastLogin')}</PropertyList.Key>
          <PropertyList.Value>
            <RelativeDate data={user.lastLogin}></RelativeDate>
          </PropertyList.Value>
        </PropertyList.Item>
      </PropertyList.Root>
    </ContentBox>
  );
}
UserDetailsBlock.displayName = 'UserDetailsBlock';
