import type { FC } from 'react';

import {
  LinkItem,
  type LinkItemProps,
} from '~/modules/link-farm/components/LinkList/LinkItem.tsx';

type LinkListProps = {
  title?: string;
  data: LinkItemProps[];
};

export const LinkList: FC<LinkListProps> = ({ title, data }) => {
  return (
    <div className="w-full flex flex-col overflow-auto">
      {title && <p className="font-bold text-[1.125em] mb-[0.5em]">{title}</p>}
      <div className="grid w-full auto-rows-min grid-cols-auto-fill-19 gap-md gap-y-md overflow-auto">
        {data.map((item) => (
          <LinkItem key={item.actionText + item.url} {...item} />
        ))}
      </div>
    </div>
  );
};

LinkList.displayName = 'LinkList';
