import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';

import type { IconName, IconVariant } from '~/components/Icon';
import { Icon } from '~/components/Icon';
import { Tooltip } from '~/components/Tooltip';
import { mergeClasses } from '~/lib/mergeClasses';
import type { VariantProps } from '~/lib/variants';
import { variants } from '~/lib/variants';

const button = variants({
  base: [
    'min-h-lg',
    'min-w-lg',
    'overflow-clip',
    'flex-shrink-0',
    'flex',
    'flex-row',
    'justify-start',
    'items-center',
    'gap-md',
    'text-white',
    'text-start',
    'text-nowrap',
    'focus:outline-none',
    'transition-all',
    'duration-150',
    'gap-md',
  ],
  variants: {
    variant: {
      default: [
        'bg-transparent',
        'hover:bg-default-inverse/10',
        'focus:bg-primary-default',
        'active:bg-primary-default',
        'px-0',
        'rounded-xs',
      ],
      primary: [
        'gap-0',
        'flex-row-reverse',
        'font-medium',
        'bg-primary-default',
        'hover:bg-primary-hover',
        'focus:bg-primary-hover',
        'active:bg-primary-pressed',
        'rounded-xs',
      ],
      active: [
        'hover:bg-primary-pressed',
        'focus:bg-primary-default',
        'bg-primary-default',
        'px-0',
        'rounded-xs',
      ],
    },
  },
});

type ButtonVariants = VariantProps<typeof button>;

interface SidebarMenuItemProps
  extends Omit<ButtonVariants, 'onlyIcon'>,
    LinkProps {
  icon: IconName;
  iconVariant?: IconVariant;
  expanded?: boolean;
  title?: string;
}

export function SidebarMenuItem({
  icon,
  className,
  children,
  iconVariant = 'solid',
  variant = 'default',
  to,
  expanded = false,
  ...props
}: SidebarMenuItemProps) {
  return (
    <Tooltip
      content={children}
      delayDuration={0}
      enabled
      side="right"
      sideOffset={8}
      triggerAsChild
      size="sm"
    >
      <Link
        to={to}
        className={mergeClasses(
          button({ variant }),
          expanded ? 'w-[10.5rem]' : 'w-2xl',
          className,
        )}
        {...props}
      >
        <span className="w-2xl h-2xl flex items-center justify-center shrink-0">
          <Icon
            type={icon}
            variant={variant === 'active' ? 'solid' : iconVariant}
            className="text-display-sm box-content"
            aria-hidden="true"
          />
        </span>
        <span
          className={mergeClasses(
            'text-wrap text-body-xs font-semibold leading-3 transition-opacity duration-150',
            expanded ? 'opacity-100 delay-150' : 'opacity-0',
            expanded ? 'block' : 'collapse',
          )}
        >
          {children}
        </span>
      </Link>
    </Tooltip>
  );
}

SidebarMenuItem.displayName = 'SidebarMenuItem';
