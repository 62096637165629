import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { SearchBar } from '~/components/SearchBar';
import { columns } from '~/modules/artist-roster/components/ArtistColumns';
import { ArtistDataTable } from '~/modules/artist-roster/components/ArtistDataTable';
import { getArtists } from '~/modules/artist-roster/components/getRosterArtists';

export function ArtistTable() {
  const [search, setSearch] = useState('');
  const { data, error } = useQuery({
    queryKey: ['artist-roster', search],
    queryFn: () => getArtists(false, search),
  });

  if (error) {
    // TODO Get proper UX for errors
    return <div className={'text-error-default'}>{error.message}</div>;
  }

  return (
    <>
      <SearchBar placeholder="Search for an artist" onSubmit={setSearch} />
      <ArtistDataTable columns={columns} data={data ?? []} />
    </>
  );
}

ArtistTable.displayName = 'ArtistTable';
