import { useWmgSession } from '~/modules/auth/hooks/useWmgSession';
import { bffClient } from '~settings/modules/bff-client';
import { BffAction } from '~settings/modules/types';

const useBffClient = (action: BffAction) => {
  const { token } = useWmgSession();

  return (...rest: unknown[]) => {
    if (action === BffAction.GET_LABEL_DETAILS) {
      const uuid = rest[0] as string;
      return bffClient[action](token, uuid);
    }

    return bffClient[action](token);
  };
};

export { useBffClient };
