import { createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';

import { Tag } from '~/components';
import { UserRole } from '~settings/components/UserRole';
import { SETTINGS_ROOT_LINK } from '~settings/constants';
import { LabelName } from '~settings/Labels/LabelName';
import type { UserLabel } from '~settings/types';
import { LabelType } from '~settings/types';

const columnHelper = createColumnHelper<UserLabel>();

export const columns = [
  columnHelper.accessor((row) => row.name, {
    id: 'name',
    cell: (cell) => <LabelName name={cell.getValue()} />,
    header: () => 'Label',
  }),
  columnHelper.accessor((row) => row.roles, {
    id: 'roles',
    cell: (cell) => <UserRole roles={cell.getValue()} />,
    header: () => 'Role',
  }),
  columnHelper.accessor((row) => row.type, {
    id: 'type',
    cell: (cell) =>
      cell.getValue() === LabelType.PARENT_LABEL ? 'Parent' : 'Sublabel',
    header: () => 'Label type',
  }),
  columnHelper.accessor((row) => row.parent, {
    id: 'parentLabel',
    cell: (cell) => {
      const parent = cell.getValue();
      if (parent) {
        return (
          <Tag asChild>
            <Link to={`${SETTINGS_ROOT_LINK}/labels/${parent.id}`}>
              {parent.name}
            </Link>
          </Tag>
        );
      }
      return '-';
    },
    header: () => 'Parent label',
  }),
];
