import { createColumnHelper } from '@tanstack/react-table';

import { RelativeDate } from '~settings/components/RelativeDate';

import { GroupInfo } from '../components/GroupInfo';
import { UserRole } from '../components/UserRole';
import type { User } from '../types';
import { UserInfo } from './UserInfo';

const columnHelper = createColumnHelper<User>();

export const columns = [
  columnHelper.accessor((row) => row.fullName, {
    id: 'fullName',
    cell: (info) => <UserInfo data={info.row} />,
    header: () => 'Name',
  }),

  columnHelper.accessor((row) => row.email, {
    id: 'email',
    cell: (cell) => cell.getValue(),
    header: () => 'Email',
  }),
  columnHelper.accessor((row) => row.labelsNames, {
    id: 'labels',
    cell: (cell) => <GroupInfo groups={cell.getValue()} />,
    header: () => 'Labels',
  }),
  columnHelper.accessor((row) => row.roles, {
    id: 'rows',
    cell: (info) => <UserRole roles={info.getValue()} />,
    header: () => 'Roles',
  }),
  columnHelper.accessor((row) => row.lastLogin, {
    id: 'lastLogin',
    cell: (info) => <RelativeDate data={info.getValue()} />,
    header: () => 'Last Login',
  }),
];
