import { createColumnHelper } from '@tanstack/react-table';

import { Instagram, Spotify, Tiktok, Youtube } from '~/components/SocialIcons';
import { Avatar, AvatarFallback } from '~/components/ui/avatar';
import { Badge } from '~/components/ui/badge';
import type { ArtistDataType } from '~/modules/artist-roster/components/getRosterArtists';

const columnHelper = createColumnHelper<ArtistDataType>();

const socialMap: {
  [key: string]: JSX.Element;
} = {
  spotify: <Spotify />,
  instagram: <Instagram />,
  tiktok: <Tiktok />,
  youtube: <Youtube />,
};

export const columns = [
  columnHelper.accessor('name', {
    id: 'name',
    header: () => 'Name',
    cell: ({ row }) => (
      <div className={'flex items-center gap-sm'}>
        <Avatar className={'rounded-full w-2xl h-2xl'}>
          <AvatarFallback className={'bg-level-02'}>
            {row.original.name.split(' ').map((x) => x[0])}
          </AvatarFallback>
        </Avatar>
        <div className={'font-bold'}>{row.original.name}</div>
      </div>
    ),
  }),
  columnHelper.accessor('label', {
    id: 'label',
    header: () => 'Label',
    cell: ({ row }) => (
      <div className={'text-body-sm font-light'}>{row.original.label}</div>
    ),
  }),
  columnHelper.accessor('quickJump', {
    id: 'quick-jump',
    header: () => 'Quick Jump',
    cell: ({ row }) => (
      <div className={''}>
        {row.original.quickJump.map((q) => (
          <Badge
            key={q}
            className={
              'mr-sm font-light text-body-sm bg-level-02 p-xs shadow rounded-xs'
            }
          >
            {q}
          </Badge>
        ))}
      </div>
    ),
  }),
  columnHelper.accessor('social', {
    id: 'social',
    header: () => 'Social',
    cell: ({ row }) => (
      <div className={'flex gap-lg items-center'}>
        {row.original.social.map((s) => (
          <div key={s.name} className={'w-[20px]'}>
            {socialMap[s.name]}
          </div>
        ))}
      </div>
    ),
  }),
];
