import type { ColumnDef } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { mergeClasses } from '~/lib/mergeClasses';

import { SETTINGS_ROOT_LINK } from '../constants';

const hoverClasses = [
  'border-px',
  'border-transparent',
  'group-hover:border-emphasis-stronger',
  'group-hover:bg-level-04',
];

export interface TableProps<TData extends { id: string }> {
  data: TData[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<TData, any>[];
  type: string;
  oddRowClassName?: string;
}

export const Table = <TData extends { id: string }>({
  data,
  type,
  columns,
  oddRowClassName = 'bg-level-02',
}: TableProps<TData>) => {
  const { t } = useTranslation('settings');
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  const navigate = useNavigate();

  return (
    <table className="w-full cursor-pointer border-separate border-spacing-0">
      <thead className="text-sm text-left leading-tight">
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                className="px-md py-sm text-subtle text-body-sm font-normal"
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext(),
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className="text-md">
        {data.length ? (
          table.getRowModel().rows.map((row, i) => {
            return (
              <tr
                key={row.id}
                className={mergeClasses(
                  i % 2 === 0 ? oddRowClassName : '',
                  'group',
                )}
                onClick={() => {
                  navigate(`${SETTINGS_ROOT_LINK}/${type}/${data[i].id}`);
                }}
              >
                {row.getVisibleCells().map((cell, i) => {
                  const isFirst = i === 0;
                  const isLast = i === row.getVisibleCells().length - 1;
                  return (
                    <td
                      key={cell.id}
                      className={mergeClasses(
                        hoverClasses,
                        `p-md`,
                        `${isFirst ? 'rounded-s-sm border-r-0' : ''}`,
                        `${isLast ? 'rounded-r-sm border-l-0' : ''}`,
                        `${!isFirst && !isLast ? 'border-x-0' : ''}`,
                      )}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={columns.length} className="p-md">
              {t('noData')}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

Table.displayName = 'Table';
