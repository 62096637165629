import { useTranslation } from 'react-i18next';

import { Icon } from '~/components/Icon';
import { Tooltip } from '~/components/Tooltip';

type Role = 'LABEL_ADMIN' | 'RELEASE_COORDINATOR' | 'VIDEO_COORDINATOR';
interface RoleInfo {
  name: string;
  description: string;
}

const RoleMap: Record<Role, RoleInfo> = {
  LABEL_ADMIN: { name: 'Label Admin', description: 'labelAdmin' },
  RELEASE_COORDINATOR: {
    name: 'Release Coordinator',
    description: 'releaseCoordinator',
  },
  VIDEO_COORDINATOR: {
    name: 'Video Coordinator',
    description: 'videoCoordinator',
  },
};

export const UserRole = ({ roles }: { roles: string[] }) => {
  const { t } = useTranslation('settings');

  if (!roles.length || !RoleMap[roles[0] as Role]) return null;

  const role = RoleMap[roles[0] as Role].name;
  const description = RoleMap[roles[0] as Role].description;

  return (
    <span>
      {role}
      <Tooltip content={t(`roles.${description}`)} side="top" delayDuration={0}>
        <Icon
          type="circle-info"
          variant="light"
          className="text-subtlest ml-sm"
          size="sm"
        />
      </Tooltip>
    </span>
  );
};

UserRole.displayName = 'UserRole';
