import { useTranslation } from 'react-i18next';

import { Tag } from '~/components';
import type { LabelDetailed } from '~/modules/settings/types';

import { ContentBox } from '../components/ContentBox';
import { HeaderSmall } from '../components/HeaderSmall';

export const LabelAdmins = ({ data }: { data: LabelDetailed }) => {
  const { adminUsers } = data;
  const { t } = useTranslation('settings');

  if (!adminUsers?.length) return null;

  return (
    <ContentBox>
      <HeaderSmall>{t('labelAdmins')}</HeaderSmall>
      <ul className="flex flex-wrap gap-y-sm gap-x-xs">
        {adminUsers.map((user) => (
          <Tag key={user.id} variant="secondary" size="lg" asChild>
            <li>
              <span className="bg-emphasis-default text-caption-sm p-xs rounded-full">{`${user.firstName[0]}${user.lastName[0]}`}</span>
              <span>{user.fullName}</span>
            </li>
          </Tag>
        ))}
      </ul>
    </ContentBox>
  );
};

LabelAdmins.displayName = 'LabelAdmins';
