import { PropertyListItem } from './PropertyListItem';
import { PropertyListKey } from './PropertyListKey';
import { PropertyListRoot } from './PropertyListRoot';
import { PropertyListValue } from './PropertyListValue';

export const PropertyList = {
  Root: PropertyListRoot,
  Item: PropertyListItem,
  Key: PropertyListKey,
  Value: PropertyListValue,
};
