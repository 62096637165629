import { useTranslation } from 'react-i18next';

import { Icon } from '~/components';

export function Header() {
  const { t } = useTranslation('settings');

  return (
    <header className="flex items-center gap-md">
      <Icon type="gear" size="lg" />
      <h1 className="py-sm text-display-sm">{t('title')}</h1>
    </header>
  );
}

Header.displayName = 'Header';
