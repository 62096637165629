import type { FC, PropsWithChildren } from 'react';
import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';

import { BrandContext } from '~/modules/branding';

const RootLayout: FC<PropsWithChildren> = ({ children }: PropsWithChildren) => {
  const brand = useContext(BrandContext);

  return (
    <>
      <Helmet>
        <title>{brand.title}</title>
        <link
          rel="icon"
          type="image/png"
          href={`/images/${brand.name?.toLowerCase()}_logo.png`}
        />
      </Helmet>
      <section className="bg-default text-default min-w-full min-h-dvh flex">
        {children}
      </section>
    </>
  );
};

RootLayout.displayName = 'RootLayout';

export { RootLayout };
