type Permissions = {
  [labelID: string]: string[];
};

export type UserResponse = {
  permissions: Permissions;
  name: string;
  email: string;
};

export const RETRY_LIMIT = 2;
export const RETRY_DELAY = 1000;

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const fetchUserPermissions = async (): Promise<UserResponse> => {
  const response = await fetch('/api/structured_permissions', {
    method: 'GET',
    credentials: 'include',
  });

  if (!response.ok) {
    throw new Error('Failed to fetch user permissions');
  }

  const data: UserResponse = await response.json();

  return data;
};

const bffClient = {
  authenticate: async (token: string) => {
    let attempts = 0;
    let response;
    while (attempts < RETRY_LIMIT) {
      attempts++;
      try {
        response = await fetch('/api/auth', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });
        if (response.ok) {
          return response;
        } else {
          console.error(`Attempt ${attempts}: Failed to authenticate`);
        }
      } catch (error) {
        console.error(
          `Attempt ${attempts}: Error during authentication`,
          error,
        );
      }
      await sleep(RETRY_DELAY);
    }
    throw new Error('Failed to authenticate after multiple attempts.');
  },

  refreshAuthToken: async () => {
    const response = await fetch('/api/refresh_auth', {
      method: 'POST',
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error('Failed to refresh auth through bff');
    }
  },

  getUserLocale: async (token: string, oktaId: string) => {
    const response = await fetch(`/api/user-info/${oktaId}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-no-gzip-response': 'true',
      },
    });
    if (!response.ok) {
      throw new Error('Failed to fetch user locale');
    }
    const responseJson = await response.json();
    return responseJson.userLocale;
  },

  getUser: async (token?: string) => {
    try {
      return await fetchUserPermissions();
    } catch (error) {
      console.error('Error fetching user permissions:', error);
      if (token) {
        try {
          await bffClient.authenticate(token);
          return await fetchUserPermissions();
        } catch (authError) {
          console.error('Error during re-authentication:', authError);
        }
      }

      throw new Error('Failed to fetch user permissions');
    }
  },

  logout: async () => {
    const response = await fetch('/api/logout', {
      method: 'POST',
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error('Failed to delete session data and cookies through bff');
    }
  },
};

export { bffClient };
