const PHONE_NUMBER_REGEX = /^\+(\d{1,3})(\d{3})(\d{3})(\d{4})$/;

export function formatPhoneNumber(phone: string | null | undefined): string {
  if (!phone) {
    return '';
  }

  const match = phone.match(PHONE_NUMBER_REGEX);

  if (!match) {
    return '';
  }

  return `+${match[1]} ${match[2]}-${match[3]}-${match[4]}`;
}
