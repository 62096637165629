import { createContext } from 'react';

interface ShowNavContextProps {
  showNav: boolean;
  setShowNav: (to: boolean) => void;
  setShowByLocale: (locale: string) => void;
}

const defaultShowNavContextValue = {
  showNav: true,
  setShowNav: () => {},
  setShowByLocale: () => {},
};

export const ShowNavContext = createContext<ShowNavContextProps>(
  defaultShowNavContextValue,
);

ShowNavContext.displayName = 'ShowNavContext';
