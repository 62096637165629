import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { env } from '~/lib/env.ts';
import { useUser } from '~/modules/auth/hooks/useUser.ts';
import { Nav } from '~/modules/navigation/components';
import { PulseInsights } from '~/modules/survey';

const PrimaryLayout = () => {
  const { email } = useUser();
  const { i18n } = useTranslation();

  return (
    <>
      <Nav />
      <Outlet />
      <PulseInsights
        email={email}
        lang={i18n.language}
        token={env('VITE_PULSE_INSIGHTS_TOKEN', false)}
      />
    </>
  );
};

PrimaryLayout.displayName = 'PrimaryLayout';

export { PrimaryLayout };
