export const LOCALES = [
  'ar',
  'en-US',
  'es',
  'fr',
  'ja-JP',
  'ko-KR',
  'pt-BR',
  'zh-CN',
] as const;
