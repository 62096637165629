import { useTranslation } from 'react-i18next';

import { HeaderSmall } from '~settings/components/HeaderSmall';
import { Table } from '~settings/components/Table';
import type { User } from '~settings/types';
import { columns } from '~settings/Users/columns.config';

import { Toolbar } from '../components/Toolbar';

export const Users = ({
  users,
  isSublabel,
}: {
  users: User[];
  isSublabel: boolean;
}) => {
  const { t } = useTranslation('settings');

  return (
    <>
      {isSublabel && (
        <HeaderSmall>{`${t('users')}(${users.length})`}</HeaderSmall>
      )}
      <Toolbar placeholder={t('searchUsers')} />
      <Table
        data={users}
        columns={columns}
        oddRowClassName="bg-level-03"
        type="users"
      />
    </>
  );
};

Users.displayName = 'LabelUsers';
