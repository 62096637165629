import type { FC } from 'react';
import { Outlet } from 'react-router-dom';

export const WmgOneLayout: FC = () => {
  return (
    <div className="bg-gradient flex h-screen w-full bg-no-repeat bg-contain bg-level-01/100">
      <Outlet />
    </div>
  );
};

WmgOneLayout.displayName = 'WmgOneLayout';
