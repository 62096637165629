import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';

type NewRelicOptions = {
  beacon?: string;
  licenseKey?: string;
  agentID?: string;
  trustKey?: string;
  applicationID?: string;
  accountID?: string;
};

const initNewRelicAgent = ({
  beacon,
  licenseKey,
  agentID,
  trustKey,
  accountID,
  applicationID,
}: NewRelicOptions = {}) => {
  if (
    !beacon ||
    !licenseKey ||
    !agentID ||
    !trustKey ||
    !applicationID ||
    !accountID
  ) {
    return;
  }

  const options = {
    init: {
      distributed_tracing: {
        enabled: true,
      },
      privacy: { cookies_enabled: true },
      session_replay: {
        enabled: true,
        block_selector: '',
        mask_text_selector: '*',
        sampling_rate: 10.0,
        error_sampling_rate: 100.0,
        mask_all_inputs: true,
        collect_fonts: true,
        inline_images: false,
        inline_stylesheet: true,
        mask_input_options: {},
      },
      ajax: {
        deny_list: [beacon, 'api2.amplitude.com'],
      },
    },
    info: {
      beacon,
      errorBeacon: beacon,
      licenseKey,
      applicationID,
      sa: 1,
    },
    loader_config: {
      accountID,
      trustKey,
      agentID,
      licenseKey,
      applicationID,
    },
  };

  return new BrowserAgent(options);
};

export { initNewRelicAgent };
