import type { SVGProps } from 'react';

type WmgLogoProps = SVGProps<SVGSVGElement>;

export function WmgLogo({ fill, stroke, ...props }: WmgLogoProps) {
  return (
    <svg
      viewBox="0 0 61 56"
      fill={fill || 'currentColor'}
      stroke={stroke || 'currentColor'}
      aria-label={props['aria-label'] || 'Wmg Logo'}
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M56.8346 10.7934C52.3039 3.2129 44.7235 0 30.1461 0C15.5688 0 7.98835 3.2129 3.45765 10.7934C0.903637 15.1609 0 22.9736 0 28C0 33.0264 0.903637 40.8454 3.45765 45.2066C7.98835 52.7871 15.5688 56 30.1461 56C44.7235 56 52.3039 52.7871 56.8346 45.2066C59.3886 40.8391 60.2923 33.0264 60.2923 28C60.2923 22.9736 59.3886 15.1546 56.8346 10.7934ZM22.8983 43.8198C21.2542 43.8198 18.8633 43.0793 17.7087 40.4437L10.4608 22.8982C8.89826 18.9449 11.9417 15.2362 15.4872 15.2362C17.1376 15.2362 19.5222 15.983 20.6768 18.6123L27.9247 36.1578C29.4872 40.1174 26.4375 43.8198 22.8983 43.8198ZM37.4756 43.8198C35.8252 43.8198 33.4343 43.0793 32.286 40.4437L25.0381 22.8982C23.4693 18.9449 26.5253 15.2362 30.0646 15.2362C31.7087 15.2362 34.0995 15.983 35.2542 18.6123L42.502 36.1578C44.0646 40.1174 41.0148 43.8198 37.4756 43.8198ZM46.5746 30.5666C44.9305 30.5666 42.5397 29.8261 41.385 27.1905L39.6154 22.9045C38.0529 18.9511 41.0964 15.2425 44.6356 15.2425C46.286 15.2425 48.6706 15.9892 49.8252 18.6185L51.5948 22.9045C53.1699 26.8579 50.1201 30.5666 46.5746 30.5666Z"
        fill="white"
      />
    </svg>
  );
}

WmgLogo.displayName = 'WmgLogo';
