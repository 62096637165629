import { useTranslation } from 'react-i18next';

import { Icon } from '~/components';
import { Avatar } from '~/components/Avatar';
import { Breadcrumbs } from '~/modules/navigation/components/Breadcrumbs';
import { SETTINGS_ROOT_LINK } from '~settings/constants.ts';

export interface UserHeaderProps {
  name: string;
}

// TODO: Current Avatar styles are different from the design (@see https://www.figma.com/design/MmWqrR1POzXR13XgUem4XP/Permissions?node-id=8795-97834&t=n6LYg5AqAAevCn4j-4)
// ? Support for `children` - to handle case with some CTA for editing the user?
export function UserHeader({ name }: UserHeaderProps) {
  const { t } = useTranslation('settings');

  return (
    <header className="flex flex-col gap-lg">
      <Breadcrumbs.Root>
        <Breadcrumbs.Item to={SETTINGS_ROOT_LINK}>
          <Icon type="gear" size="sm" variant="regular" className="mr-sm" />
          {t('title')}
        </Breadcrumbs.Item>
        <Breadcrumbs.Item to={SETTINGS_ROOT_LINK}>
          {t('users')}
        </Breadcrumbs.Item>
        <Breadcrumbs.Item>{name}</Breadcrumbs.Item>
      </Breadcrumbs.Root>

      <div className="flex items-center gap-md text-strong-on-dark">
        <Avatar name={name} size="lg" />
        <h1 className="text-display-sm">{name}</h1>
      </div>
    </header>
  );
}
UserHeader.displayName = 'UserHeader';
