import { Fragment, type PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet-async';

import { AdaLogo } from '~/components/AdaLogo';
import { WmgLogo } from '~/components/WmgLogo';
import { BrandContext, defaultBrandContext } from '~/modules/branding';
import { type Brand } from '~/modules/branding';
import { AdaOktaConfig } from '~/modules/branding/auth/ada-okta-config.ts';
import { WmgOktaConfig } from '~/modules/branding/auth/wmg-okta-config.ts';
import AdaThemeCssUrl from '~/styles/theme-ada.css?url';
import WmgThemeCssUrl from '~/styles/theme-wmg.css?url';

type BrandProviderProps = {
  localBrand?: string;
  hostname: string;
};

const BrandProvider = ({
  children,
  localBrand,
  hostname,
}: PropsWithChildren<BrandProviderProps>) => {
  const brand: Brand = defaultBrandContext;

  const wmgOneDomains = [
    'one.wmg.com',
    'staging.one.wmg.com',
    'dev.one.wmg.com',
    localBrand === 'WMG' && 'localhost',
  ];
  const adaHubDomains = [
    'partners.ada-music.com',
    'staging.partners.ada-music.com',
    'dev.partners.ada-music.com',
    localBrand === 'ADA' && 'localhost',
  ];

  if (wmgOneDomains.includes(hostname)) {
    brand.name = 'WMG';
    brand.title = 'WMG One';
    brand.oktaConfig = WmgOktaConfig;
    brand.meta = (
      <Helmet>
        <link type="text/css" rel="stylesheet" href={WmgThemeCssUrl} />
      </Helmet>
    );
    brand.logo = <WmgLogo />;
  } else if (adaHubDomains.includes(hostname)) {
    brand.name = 'ADA';
    brand.title = 'ADA';
    brand.oktaConfig = AdaOktaConfig;
    brand.meta = (
      <Helmet>
        <link type="text/css" rel="stylesheet" href={AdaThemeCssUrl} />
      </Helmet>
    );
    brand.logo = <AdaLogo className="text-primary-default" />;
  } else {
    brand.name = null;
    brand.title = '';
    brand.meta = <Fragment />;
  }

  return (
    <BrandContext.Provider value={brand}>{children}</BrandContext.Provider>
  );
};

BrandProvider.displayName = 'BrandProvider';

export { BrandProvider };
export type { BrandProviderProps };
