import type { FC } from 'react';

import { WelcomeLogo } from '~/modules/link-farm/components/WelcomeLogo/WelcomeLogo.tsx';

import { PageTemplate } from './components/PageTemplate';

export const Home: FC = () => {
  return (
    <PageTemplate>
      <div className="pt-[6em] flex flex-row gap-[3em] overflow-auto">
        <WelcomeLogo />
      </div>
    </PageTemplate>
  );
};

Home.displayName = 'LinkFarm';
