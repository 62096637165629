export function Instagram() {
  return (
    <svg
      role="img"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 4.20703C10.2148 4.20703 12.043 6.03516 12.043 8.25C12.043 10.5 10.2148 12.293 8 12.293C5.75 12.293 3.95703 10.5 3.95703 8.25C3.95703 6.03516 5.75 4.20703 8 4.20703ZM8 10.8867C9.44141 10.8867 10.6016 9.72656 10.6016 8.25C10.6016 6.80859 9.44141 5.64844 8 5.64844C6.52344 5.64844 5.36328 6.80859 5.36328 8.25C5.36328 9.72656 6.55859 10.8867 8 10.8867ZM13.1328 4.06641C13.1328 3.53906 12.7109 3.11719 12.1836 3.11719C11.6562 3.11719 11.2344 3.53906 11.2344 4.06641C11.2344 4.59375 11.6562 5.01562 12.1836 5.01562C12.7109 5.01562 13.1328 4.59375 13.1328 4.06641ZM15.8047 5.01562C15.875 6.31641 15.875 10.2188 15.8047 11.5195C15.7344 12.7852 15.4531 13.875 14.5391 14.8242C13.625 15.7383 12.5 16.0195 11.2344 16.0898C9.93359 16.1602 6.03125 16.1602 4.73047 16.0898C3.46484 16.0195 2.375 15.7383 1.42578 14.8242C0.511719 13.875 0.230469 12.7852 0.160156 11.5195C0.0898438 10.2188 0.0898438 6.31641 0.160156 5.01562C0.230469 3.75 0.511719 2.625 1.42578 1.71094C2.375 0.796875 3.46484 0.515625 4.73047 0.445312C6.03125 0.375 9.93359 0.375 11.2344 0.445312C12.5 0.515625 13.625 0.796875 14.5391 1.71094C15.4531 2.625 15.7344 3.75 15.8047 5.01562ZM14.1172 12.8906C14.5391 11.8711 14.4336 9.41016 14.4336 8.25C14.4336 7.125 14.5391 4.66406 14.1172 3.60938C13.8359 2.94141 13.3086 2.37891 12.6406 2.13281C11.5859 1.71094 9.125 1.81641 8 1.81641C6.83984 1.81641 4.37891 1.71094 3.35938 2.13281C2.65625 2.41406 2.12891 2.94141 1.84766 3.60938C1.42578 4.66406 1.53125 7.125 1.53125 8.25C1.53125 9.41016 1.42578 11.8711 1.84766 12.8906C2.12891 13.5938 2.65625 14.1211 3.35938 14.4023C4.37891 14.8242 6.83984 14.7188 8 14.7188C9.125 14.7188 11.5859 14.8242 12.6406 14.4023C13.3086 14.1211 13.8711 13.5938 14.1172 12.8906Z"
        fill="white"
      />
    </svg>
  );
}

Instagram.displayName = 'Instagram';
