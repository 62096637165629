import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { RootLayout } from '~/components/RootLayout';
import { env } from '~/lib/env.ts';
import { UserProvider } from '~/modules/auth/components';
import { AuthenticationProvider } from '~/modules/auth/components/AuthenticationProvider';
import { WmgSessionProvider } from '~/modules/auth/contexts';
import { BrandProvider } from '~/modules/branding';
import { ThemeProvider } from '~/modules/branding/components/ThemeProvider.tsx';
import { ShowNavProvider } from '~/modules/navigation';

import { I18nProvider } from './i18n';
import { Router } from './router';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
    },
  },
});

export function App() {
  return (
    <I18nProvider>
      <HelmetProvider>
        <BrandProvider
          localBrand={env('VITE_BRAND', false)}
          hostname={window.location.hostname}
        >
          <ThemeProvider>
            <QueryClientProvider client={queryClient}>
              <RootLayout>
                <ShowNavProvider>
                  <AuthenticationProvider>
                    <WmgSessionProvider>
                      <UserProvider>
                        <BrowserRouter>
                          <Router />
                        </BrowserRouter>
                      </UserProvider>
                    </WmgSessionProvider>
                  </AuthenticationProvider>
                </ShowNavProvider>
              </RootLayout>
            </QueryClientProvider>
          </ThemeProvider>
        </BrandProvider>
      </HelmetProvider>
    </I18nProvider>
  );
}

App.displayName = 'App';
