import { convertCoopLocale } from './convertCoopLocale';

const coopDetector = {
  name: 'coopDetector',
  lookup() {
    const userLocale = localStorage.getItem('userLocale');
    if (userLocale) {
      try {
        return convertCoopLocale(JSON.parse(userLocale));
      } catch (error) {
        console.error('Error parsing userLocale from localStorage:', error);
      }
    }
    return undefined;
  },

  cacheUserLanguage(lng: string) {
    localStorage.setItem('userLocale', JSON.stringify(convertCoopLocale(lng)));
  },
};

export { coopDetector };
