import OktaAuth from '@okta/okta-auth-js';

import type { Brand } from '~/modules/branding';

import { getOktaConfig } from './okta-config';

const getOktaAuth = (brand: Brand) => {
  const oktaConfig = getOktaConfig(brand);

  return new OktaAuth({
    // WidgetOptions defines `authParams` as optional, but it won't be based on the
    // default setup.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    issuer: oktaConfig.authParams.issuer,
    clientId: oktaConfig.clientId,
    redirectUri: window.location.origin + '/login/callback',
    scopes: oktaConfig.authParams?.scopes,
    tokenManager: {
      autoRenew: true,
      expireEarlySeconds: 5 * 60, // To prevent time out of sync problem between client and server
      secure: true,
      storage: window.localStorage,
    },
  });
};

export { getOktaAuth };
